import React, { useState, useRef } from 'react';
import './verification.css';
import { Navigate, useNavigate } from 'react-router-dom';
import {kicksharingAPITokenStoreInstance} from 'KicksharingAPITokenStore';
import { apiUrl } from 'config';

interface PhoneCodeInputProps {
    onCodeChange: (code: string) => void;
}

const PhoneCodeInput: React.FC<PhoneCodeInputProps> = ({ onCodeChange }) => {
    const navigate = useNavigate();
    const [code, setCode] = useState<string[]>(['', '', '', '', '', '']);
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const handleInputChange = async (index: number, value: string) => {
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);
        const encodedToken = encodeURI(kicksharingAPITokenStoreInstance.token);
        if (newCode.join('').length === 6) {
            const data = {
                token: encodedToken,
                verificationCode: newCode.join('')
            };
            const params = new URLSearchParams(data);
            const response = await fetch(`${apiUrl}/auth/verifyPhone?${params.toString()}`, {
                method: 'POST',
            });
            if (response.ok) {
                kicksharingAPITokenStoreInstance.confirmToken();
                navigate('/scooters');
            }
        }
        if (value && inputRefs.current[index + 1]) {
            inputRefs.current[index + 1].focus();
        }

        onCodeChange(newCode.join(''));
    };

    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        const isBackspace = event.key === 'Backspace';

        if (isBackspace && !code[index] && inputRefs.current[index - 1]) {
            inputRefs.current[index - 1].focus();
        }
    };

    return (
        <div className="container">
            <form className="Auth">
                <p className="Auth__title">Код подтверждения</p>
                <p className='Auth__text_send'>Код отправлен на номер</p>
                <div className='code'>
                    {code.map((value, index) => (
                        <input
                            className='code__box'
                            key={index}
                            ref={inputRef => {
                                if (inputRef) {
                                    inputRefs.current[index] = inputRef;
                                }
                            }}
                            type="text"
                            maxLength={1}
                            value={value}
                            onChange={event => handleInputChange(index, event.target.value)}
                            onKeyDown={event => handleInputKeyDown(event, index)}
                        />
                    ))}
                </div>
                <a className="Auth__back" href="">Изменить номер для отправки</a>
            </form>
        </div>
    );
};
export default PhoneCodeInput;