import { kicksharingAPITokenStoreInstance } from "KicksharingAPITokenStore";
import { apiUrl } from "config";
import React, {  useEffect, useState } from "react";

export type CommandDefinition = {
    command: string;
    ruTitle: string;
}

export function useRentableUnitCommands(unitId: string) : Array<CommandDefinition> {
    const [commands,setCommands]  = useState<CommandDefinition[]>([])

    useEffect(() => {
        async function fetchCommands() {
            const request = await fetch(`${apiUrl}/scooters/${unitId}/commands`, {
                headers: {
                  Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
                },
              });
          
            const data:CommandDefinition[] = await request.json();
            setCommands(data)
        }
        fetchCommands()
    },[unitId])

    return commands
}