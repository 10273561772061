import {kicksharingAPITokenStoreInstance} from 'KicksharingAPITokenStore';
import {scooterStoreInstance} from 'ScootersStore';
import { apiUrl } from 'config';

// const API_URL = 'https://sharing-staging.akku-green.ru/api/v1';

// интерфейсы 
interface DeactivateScooterParams {
  scooterId: string;
  deactivationType: string;
  comment: string;
}


// Отправка команды на самокат
export const SendCommand = async (scooterId: string, command: string) => {
  try {
    const url = `${apiUrl}/scooters/${scooterId}/command?command=${encodeURIComponent(command)}`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
      },
    });
    return response.ok;
  } catch (error) {
    console.error(error);
  }
};

// Возврат на линию
export const CancelDeactivation = async (scooterId: string) => {
  try {
    const response = await fetch(`${apiUrl}/scooters/${scooterId}/deactivation`, {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
      },
    });
    return response.ok;
  } catch (error) {
    console.error(error);
  }
};

export async function RentCommand(rentId:string, command:string) {
  try {
    const response = await fetch(`${apiUrl}/rents/${rentId}/${command}`, {
      method: 'POST',
      headers: {
        Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
      },
    });
    return response.ok;
  } catch (error) {
    console.error(error);
  }
}

//Завершение аренды
export const EndRent = async (rentId: string) => {
  return RentCommand(rentId, "endRent");
};

/** If the rent is requested to end, but the scooter can't confirm successful lock.
 * (e.g. full discharge), the command will cause the rent to skip lock and proceed further to end the rent
 */
export const SkipFinalScooterLock = async (rentId: string) => {
  return RentCommand(rentId, "skipFinalScooterLock");
};

// Деактивировать со статуcом
export const deactivateScooter = async ({ scooterId, deactivationType, comment }: DeactivateScooterParams) => {
  const scooterData = scooterStoreInstance.renableUnits.find((item) => item.scooterPublicID === scooterId);
  const scooterIdCom = scooterData.id;

  const response = await fetch(`${apiUrl}/scooters/${scooterIdCom}/deactivation?deactivationType=${deactivationType}&comment=${comment}`, {
    method: 'PUT',
    headers: {
      Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to deactivate scooter');
  }

  return response.json();
};