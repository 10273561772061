import { observer } from 'mobx-react-lite';
import L, { LatLngExpression, PointExpression, icon } from 'leaflet';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polygon, ZoomControl } from 'react-leaflet';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Select, { SelectChangeEvent } from '@mui/material/Select';


import 'leaflet/dist/leaflet.css';
import './Map.css';

import {scooterStoreInstance, ScooterStoreItem, VegaMtXCanStoreItem, VehicleStoreItem } from 'ScootersStore';


import PopupComp from 'components/Popup/Popup';
import Polygons from 'components/Polygons/Polygons';
import { useFreshScooterData } from 'hooks/useFreshScooterData';
import { usePersistentMapViewport } from 'hooks/usePersistentMapViewport';
import { citiesStoreInstance } from 'CitiesStore';
import { FormControl } from '@mui/material';

const iconSize:PointExpression = [76 * 0.7, 92 * 0.7];
const iconAnchor:PointExpression = [36 * 0.7, 92 * 0.7];

function mapIcon(filename:string) {
  return icon({
    className: `${filename}`,
    iconUrl: `./${filename}.png`,
    iconSize: iconSize,
    iconAnchor: iconAnchor,
  })
}

const scooter_active = mapIcon('scooter-active-icon');
const scooter_active_unlocked = mapIcon('scooter-active-unlocked-icon');
const scooter_active_pushed = mapIcon('scooter-active-pushed-icon');

const charging = mapIcon("charging-icon");
const deactivation = mapIcon("deactivation-icon");
const scooter_offline = mapIcon("scooter-offline-icon");
const scooter_in_rent = mapIcon("scooter-rent-icon");
const scooter_in_rent_locked = mapIcon("scooter-rent-locked-icon");

const car_offline = mapIcon("car-offline-icon");
const car_active = mapIcon("car-active-icon");
const car_active_engine_on = mapIcon("car-active-engine-on-icon");
const car_active_parking_brake = mapIcon("car-active-parking-brake-icon");
const car_active_charging = mapIcon("car-active-charging-icon");


interface MapProps {
  scrollWheelZoom: boolean;
  children?: ReactNode;
}

type MarkerLayerProps = {
  vehicles: VehicleStoreItem[]
}

const MarkerLayer = observer((props:MarkerLayerProps) => (<>{props.vehicles
  .filter(({ lat, lon }) => !isNaN(lat) && !isNaN(lon))
  .map((vehicle) => {
  // return <Marker position={[55,37]} icon={(() => { return car_active_engine_on})()} />
  let icon:L.Icon = null;

  let charge = Number.NaN
  
  switch(vehicle.type) {
  case "Car":
    const vega = vehicle as VegaMtXCanStoreItem; 
    charge = vega.chargePercentage;
    if (!vega.online) {
      icon = car_offline;
    }
    // TODO: add in rent indication here
    else if (vega?.deactivation?.typeId === 'LowCharge') {
      icon = charging;
    }
    // TODO: for now deactivation is ignored
    //else if (vega.deactivation === null) {
    else if(vega.throttleEnabled)
    icon = car_active_engine_on;
    else if(vega.charging)
    icon = car_active_charging;
    else if(vega.handBrakeActive)
    icon = car_active_parking_brake;
    else 
    icon = car_active;
    // }
    // else {
    //   return deactivation;
    // }
      break;
  case "Scooter":
    const scooter = vehicle as ScooterStoreItem;
    charge = scooter.batteryChargePercentage;
    if (!scooter.online) {
      icon = scooter_offline;
    }
    else if (scooter.ongoingRentID) {
      icon = scooter.locked ? scooter_in_rent_locked : scooter_in_rent;
    }
    else if (scooter?.deactivation?.typeId === 'LowCharge') {
      icon = charging;
    }
    else if (scooter.deactivation === null) {
      if(!scooter.locked)
        icon = scooter_active_unlocked;
      else if (scooter.moving)
      icon = scooter_active_pushed;
      else 
      icon = scooter_active;
    }
    else {
      icon = deactivation;
    }
    break;
  default:
    console.warn("Unknown vehicle type on map is ignored:", vehicle.type);
}

if (icon == null)
  return null;
return (
  <Marker
      position={[vehicle?.lat, vehicle?.lon]}
      key={vehicle.publicID}
      icon={icon}>
      <Popup>
        <PopupComp id={vehicle.publicID} status={vehicle?.deactivation?.typeId} battery={charge}></PopupComp>
      </Popup>
    </Marker>
)})}</>))

const Map: FC<MapProps> = observer(({ children, scrollWheelZoom }) => {
  const [map, setMap] = React.useState<L.Map>(null);

  const [filter, setFilter] = useState({
    id: '',
    batteryChargeMin: '',
    batteryChargeMax: '',
    deactivationType: ''
  });

  useFreshScooterData()

  const initialViewport = usePersistentMapViewport(map, "latestState")


  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
    if (value.length >= 2) {      
      // trying to find scooter to navigate to it
      const found = scooterStoreInstance.renableUnits.filter(s => s.publicID.includes(value))
      if(found.length == 1){
        const vehicle = found[0] as VehicleStoreItem;
        if(vehicle.lat && vehicle.lon) {
          map.setView([vehicle.lat,vehicle.lon],15, {animate:true, duration:2.0})
        }
      }
    }
  };
  const handleChange = (event: SelectChangeEvent) => {
    setFilter({ ...filter, deactivationType: event.target.value as string });
  };

  const userCities = new Set<string>(scooterStoreInstance.renableUnits.map(s => s.city))
  const jumpToCityOptions = userCities.size<=1 ? [] :
    (citiesStoreInstance.Cities
      .filter(c => userCities.has(c.enName))
      .map(c => (
        <MenuItem value={c.enName}>{c.ruName}</MenuItem>
      ))
      )
  const onJumpToCity = (event: SelectChangeEvent) => {
    const city = citiesStoreInstance.Cities.find(c => c.enName === event.target.value as string)
    if(city) {
      map.setView([city.centre.coordinates[1],city.centre.coordinates[0]],14,{animate:true, duration:2.0});
    }
  }

  return (
    <div>
      <div className='map__filters'>
        <span><b>Номер</b></span>
        <input type="text" name="id" value={filter.id} onChange={handleFilterChange} />
        <span><b>Заряд: </b></span>
        <input
          type="number"
          name="batteryChargeMin"
          placeholder='от'
          value={filter.batteryChargeMin}
          onChange={handleFilterChange}
        />
        <input
          type="number"
          placeholder='до'
          name="batteryChargeMax"
          value={filter.batteryChargeMax}
          onChange={handleFilterChange}
        />
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="deactivation-select-label"><b>Деактивация</b></InputLabel>
          <Select
            labelId="deactivation-select-label"
            id="deactivation-select"
            value={filter.deactivationType || ''}
            label="Деактивация"
            onChange={handleChange}
            style={{ minWidth: '120px', marginLeft: '10px' }} 
          >
            <MenuItem value={''}>Все самокаты</MenuItem>
            {scooterStoreInstance.filterSelectOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}          
          </Select>
        </FormControl>
        { userCities.size>1 &&
        (          
          <FormControl sx={{ml:2, minWidth: 200 }}>
            <InputLabel id="jump-to-city-select-label"><b>Перейти к городу</b></InputLabel>
            <Select
              onChange={onJumpToCity}              
              label="Перейти к городу"
              labelId="jump-to-city-select-label"
              id="jump-to-city-select"
              value=''
              >
              {jumpToCityOptions}
            </Select>
            </FormControl>
        )}
      </div>
      <div className="map__container">
        <MapContainer
          center={initialViewport.centre}
          zoom={initialViewport.zoomLevel}
          scrollWheelZoom={true}
          zoomControl={false}
          ref={setMap}
          >
          <ZoomControl position="bottomright" />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {children}
          <MarkerLayer vehicles={scooterStoreInstance.renableUnits.filter(
            (unit) => {
              let unitCharge:number = null;
              switch(unit.type) {
                case "Scooter": unitCharge = (unit as ScooterStoreItem).batteryChargePercentage; break;
                case "Car": unitCharge = (unit as VegaMtXCanStoreItem).chargePercentage; break;
              }


              return (!filter.id || unit.scooterPublicID.includes(filter.id)) &&
              (!filter.batteryChargeMin || unitCharge >= Number.parseInt(filter.batteryChargeMin)) &&
              (!filter.batteryChargeMax || unitCharge <= Number.parseInt(filter.batteryChargeMax)) &&
              (filter.deactivationType==='' || 
                (filter.deactivationType === 'active' && unit.deactivation == null) ||
                (filter.deactivationType === unit.deactivation?.typeId))
              }
          ).map(ri => ri as VehicleStoreItem)}/>
          <Polygons></Polygons>
        </MapContainer>
      </div>
    </div>

  );
});


export default Map;





