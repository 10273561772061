import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {scooterStoreInstance} from "ScootersStore";
import React from "react";
import './StatusScooter.css';

type StatusScooterProps = {
  isPopup: boolean; 
  status: null | string;
}
const StatusScooter: React.FC<StatusScooterProps> = observer(({isPopup, status}) => {
    const { scooterId } = useParams<{ scooterId: string }>();
    const statusDeactivation = scooterStoreInstance.renableUnits.find(item => item.scooterPublicID === scooterId)?.deactivation;
    let buttonClass = "";

    if(!statusDeactivation) {
      buttonClass = "StatusScooter__button_active";
    } else {
    switch(statusDeactivation?.typeId) {
      case "LowCharge":
        buttonClass = "StatusScooter__button_charging";
        break;
      default:
        buttonClass = "StatusScooter__button_deactivation";
      }
    }

    return (
        <div className="StatusScooter__container">
            <h3 className="StatusScooter__title">Статус самоката</h3> 
            
            <button className={`StatusScooter__button ${buttonClass}`}>
            {
              !statusDeactivation ? "Активный" : (
                <>
              {scooterStoreInstance.filterSelectOptions.find(option => option.value === statusDeactivation.typeId)?.text ?? 'загрузка'}
              {!statusDeactivation?.comment ? '': ': '}{statusDeactivation?.comment}
                </>
                )
            }
            </button>
        </div>
    )
});

export default StatusScooter;