export function formatDateTime(dateString: string): string {
    if (dateString == null) {
        return "";
    }
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const formatDate = `${day}.${month}.${year}, ${hours}:${minutes}`;
    return formatDate;
}
