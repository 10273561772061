import React from "react";
import { NavLink } from "react-router-dom";
import "./NavigationMenu.css";
import Button from "components/Button/Button";
import { apiUrl } from "config";
import { observer } from "mobx-react-lite";
import { accessRightsStoreInstance } from "AccessRightsStore";
const NavigationMenu= observer(() => {
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("confirmedToken");
    window.location.href = "/auth";
  };

  const access = accessRightsStoreInstance.AccessRights

  return (
    <nav className="navigation">
      <ul>
        {access && access.scooterListing && (
          <li>
            <NavLink to="/scooters">Список</NavLink>
          </li>)
        }
        {access && access.scooterListing && (
          <li>
            <NavLink to="/map">Карта</NavLink>
          </li>)
        } 
        {access && access.movementTracksChart && (
          <li>
            <NavLink to="/scootersTrack">Перемещения самокатов</NavLink>
          </li>
        )}
        {access && access.financialAnalysis && (
          <li>
            <NavLink to="/financeStats">Финансовая сводка</NavLink>
          </li>
        )}
        <li>
          <a className="navigation__logout" onClick={handleLogout} href="">
            Выход
          </a>
        </li>
      </ul>
    </nav>
  );
});

export default NavigationMenu;
