import React, { useState } from 'react';
import {kicksharingAPITokenStoreInstance} from 'KicksharingAPITokenStore';
import {scooterStoreInstance} from 'ScootersStore';
import { useParams } from 'react-router-dom';
import './ScooterCommand.css';
import ButtonCommand from 'components/ButtonCommand/ButtonCommand';
import { observer } from 'mobx-react-lite';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { SendCommand, EndRent, SkipFinalScooterLock } from '../../api';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Menu, MenuItem, Button, createTheme, LinearProgress } from '@mui/material';
import { CommandDefinition, useRentableUnitCommands } from 'hooks/useRentableUnitCommands';
import { DialogState } from 'components/ScooterDeactivation/ScooterDeactivation';
import { useVehicleTypeNames } from 'hooks/useVehicleTypeString';

const ScooterCommand: React.FC = observer(() => {
    const { scooterId } = useParams<{ scooterId: string }>();
    const unit = scooterStoreInstance.renableUnits.find(item => item.publicID == scooterId);
    const statusDeactivation = unit?.deactivation;
    const rentId = scooterStoreInstance.renableUnits.find(item => item.publicID == scooterId)?.ongoingRentID;
    console.log(statusDeactivation);

    const vehicleCases = useVehicleTypeNames(unit?.type);

    const commandDefinitions = useRentableUnitCommands(scooterId)

    const [selectedCommand, setSelectedCommand] = useState<string | null>(null);
    const [selectedCommandTitle, setSelectedCommandTitle] = useState<string | null>(null);
    const [dialogState, setDialogState] = useState<DialogState>({state: "hidden"});

    const handleButtonClick = (command:CommandDefinition) => {
        setSelectedCommand(command.command);
        setSelectedCommandTitle(command.ruTitle);
        setDialogState({state:'confirm', message: `Вы уверены, что хотите отправить команду "${command.ruTitle}" на ${vehicleCases.nominative} ${scooterId}?`});
    };

    const handleConfirmSendCommand = () => {
        setDialogState({state:'inProgress', message: `Команда "${selectedCommandTitle}" выполняется...`});

        (() => {
        if (selectedCommand) {
            const scooterData = scooterStoreInstance.renableUnits.find(item => item.scooterPublicID == scooterId);
            const scooterIdCom = scooterData.id;
            const rentId = scooterData.ongoingRentID;
            switch(selectedCommand) {
                case 'endRent':
                    return EndRent(rentId);
                case 'skipFinalScooterLock':
                    return SkipFinalScooterLock(rentId);
                default:
                    return SendCommand(scooterIdCom, selectedCommand);
            }
        }
        })().then(res => {
            if(res) {
                setDialogState({state:'success', message: `Команда "${selectedCommandTitle}" успешно выполнена`});
            } else {
                setDialogState({state:'error', message: `Ошибка выполнения команды "${selectedCommandTitle}"`});
            }
        }).catch(err => {
            setDialogState({state:'error', message: `Ошибка выполнения команды "${selectedCommandTitle}": ${err}`});
        })
    };

    const handleCancelCommand = () => {
        setSelectedCommand(null);
        setSelectedCommandTitle(null);
        setDialogState({state:'hidden'});
    };

    const endRent = async () => {
        setSelectedCommand('endRent');
        setSelectedCommandTitle('Завершить аренду');
        setDialogState({state:'confirm', message: `Вы уверены, что хотите завершить аренду ${vehicleCases.genitive} ${scooterId}?`});
    }

    const skipFinalScooterLock = async () => {
        setSelectedCommand('skipFinalScooterLock');
        setDialogState({state:'confirm', message: `Вы уверены, что хотите пропустить блокировку ${vehicleCases.genitive} ${scooterId}?`});
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleRentClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    let title:JSX.Element = null;
    let dialogText: string = null;
    let dialogActions:JSX.Element = null;
    switch(dialogState.state){
        case "inProgress":
            title = <DialogTitle>Подождите...</DialogTitle>;
            dialogText = dialogState.message;
            break;
        case "success":
            title = <DialogTitle>Успешно ✅</DialogTitle>;
            dialogText = dialogState.message;
            dialogActions = <Button onClick={handleCancelCommand}>Закрыть</Button>;
            break;
        case "error":
            title = <DialogTitle>Ошибка ❌</DialogTitle>;
            dialogText = dialogState.message;
            dialogActions = <Button onClick={handleCancelCommand}>Закрыть</Button>;
            break;
        case "confirm":
            title = <DialogTitle>Подтвердите команду</DialogTitle>;
            dialogText = dialogState.message;
            dialogActions = <>
                <Button onClick={handleCancelCommand}>Отмена</Button>
                <Button onClick={handleConfirmSendCommand} autoFocus>
                    Подтвердить
                </Button>
            </>;
            break;
    }
  
    return (
        <div className='ScooterCommand__container'>
            <h3 className='ScooterCommand__title'>Команды</h3>
            <div className='ScooterCommand__commands'>
                {commandDefinitions.map((cmdDefinition) => (
                    <ButtonCommand
                        key={cmdDefinition.ruTitle} 
                        buttonText={cmdDefinition.ruTitle}
                        handleButtonClick={() => handleButtonClick(cmdDefinition)}
                        />
                ))}
                {!rentId ? null :
                (
                    <>
                    <Button size="medium" sx={{ml:1, height:"40px"}} variant="contained" onClick={handleRentClick} >Аренда</Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={endRent}>Завершить</MenuItem>
                        <MenuItem onClick={skipFinalScooterLock}>Пропустить блокировку {vehicleCases.genitive}</MenuItem>
                    </Menu>
                </>
                )}
            </div>

            <Dialog open={dialogState.state != 'hidden'} onClose={handleCancelCommand}>
                {title}
                <DialogContent>
                    <DialogContentText>{dialogText}</DialogContentText>
                    {dialogState.state=='inProgress' && <LinearProgress />}
                    </DialogContent>
                <DialogActions>
                    {dialogActions}
                </DialogActions>
            </Dialog>
        </div>)
})
export default ScooterCommand;