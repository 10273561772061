import React from 'react';
import './ButtonStatus.css';


interface ButtonProps {
    status: string;
  }
  
const ButtonStatus: React.FC<ButtonProps> = ({status}) => {
  let className = 'ButtonStatus';

  switch (status) {
    case 'в сети':
      className += ' ButtonStatus_online';
      break;
    case 'в аренде':
      className += ' ButtonStatus_rent';
      break;
    case 'не в аренде':
      className += ' ButtonStatus_not_rent';
      break;
    default:
      className += ' ButtonStatus_offline';
      break;
  }
    return (
      <div 
      className={className}
      >{status}
      </div>  
    );
}

export default ButtonStatus;

