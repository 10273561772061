import { accessRightsStoreInstance } from "AccessRightsStore";
import { kicksharingAPITokenStoreInstance } from "KicksharingAPITokenStore";
import { apiUrl } from "config";
import { autorun, makeAutoObservable, runInAction, values } from "mobx";

type DeactivationTypeJSON = {
    id: string,
    ruName: string
}

export class DeactivationTypesStore {
    public Ids:string[] = []

    public RussianNames : {
        [id: string]: string ;
      } = {}

    public constructor()  {
       makeAutoObservable(this);
    }
}

export const deactivationTypesStoreInstance = new DeactivationTypesStore();

autorun(async () => {
    if (kicksharingAPITokenStoreInstance.token &&
        kicksharingAPITokenStoreInstance.confirmedToken &&
        accessRightsStoreInstance?.AccessRights?.scooterListing) {
        console.log("Fetching available deactivation types")
        try{
        const deactivationsFetchResult = await fetch(`${apiUrl}/deactivations/types`, {
          headers: {
            Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
          },
        });
        if(deactivationsFetchResult.ok) {
            const deactivationTypes: DeactivationTypeJSON[] = await deactivationsFetchResult.json();
            var russianTranslations:{
                [envVar: string]: string ;
              } = {}
            var ids:string[] = []
            deactivationTypes.forEach(entry => {
                russianTranslations[entry.id] = entry.ruName;
                ids.push(entry.id)
            });
            
            runInAction(()=> {
                deactivationTypesStoreInstance.Ids = ids;
                deactivationTypesStoreInstance.RussianNames = russianTranslations;
            })

            console.log(`Successfully obtained ${Object.keys(russianTranslations).length} deactivation type translations`);
        }

        }
        catch(e) {
            console.error("Error during deactivation types fetch", e)
        }
    }
})