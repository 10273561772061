import * as LeafLet from "leaflet";
import React, { useMemo } from "react";

export type MapViewport = {
    centre:[number,number]
    zoomLevel: number
}

export function usePersistentMapViewport(map:LeafLet.Map, viewName:string| null = null) {
  const effectiveViewName = viewName ?? ""

  const initialCenter = JSON.parse(localStorage.getItem(`${effectiveViewName}_mapCenter`)) || [
    54.72847081143663, 37.17888236045838,
    ];
  const initialZoom = Number(localStorage.getItem(`${effectiveViewName}_mapZoom`) || 13);

  function saveMapState() {
    localStorage.setItem(`${effectiveViewName}_mapCenter`, JSON.stringify(map.getCenter()));
    localStorage.setItem(`${effectiveViewName}_mapZoom`, map.getZoom().toString());
  }

  React.useEffect(() => {
    if (!map) return;
    map.on("move", saveMapState);
    return () => {
      map.off("move", saveMapState);
    };
  }, [map]);


  const initialViewport:MapViewport = useMemo(() =>{ return {
    centre : initialCenter,
    zoomLevel : initialZoom
  } },[]) // only saved during initial load

  return initialViewport
}