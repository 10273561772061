import { IconButton, Tooltip } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import React from "react";
import { useMemo } from "react"
import { formatCurrencyRUB } from 'utils/formatters';

export type CityStats = {
    Name: string,
    Revenue: number,
    ScooterCount: number,
    RevenuePerScooter:number
}

export type FinanceTableProps = {
    data: CityStats[]
}

export function FinanceTable(props: FinanceTableProps) {
    const {data} = props
    const columns = useMemo<MRT_ColumnDef<CityStats>[]>(() => [
        {
          accessorKey: 'Name',
          header: 'Город',
          size: 100
        },
        {
            accessorKey: 'Revenue',
            header: 'Оборот',
            size: 100,
            Cell:({ cell }) => formatCurrencyRUB(cell.getValue<number>()),
          },          
          {
            accessorKey: 'RevenuePerScooter',
            header: 'Выручка на самокат',
            size: 50,
            Header: ({ column }) => <div style={{'textAlign':'center'}}>{column.columnDef.header}</div>,
            Cell:({ cell }) => formatCurrencyRUB(cell.getValue<number>()),
          },
          {
            accessorKey: 'ScooterCount',
            size: 50,
            header: "Самокатов в городе",
            Header: ({ column }) => (
                <>
                <span>{column.columnDef.header}</span>
                <Tooltip title="Может быть дробным, если самокаты перемещались между городами">
                    <IconButton onClick={(e) => {e.stopPropagation()}}>
                        <HelpOutlineOutlinedIcon />
                    </IconButton>
                </Tooltip>
                </>
              ),
            Cell:({ cell }) => parseFloat(cell.getValue<number>().toFixed(1)),
          },
        
    ],[]);

    return (
    <MaterialReactTable
        columns={columns} data={data}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        enableSorting={false}
        muiTableBodyRowProps={{ hover: false }}        
        initialState={{density:'compact'}}        
        muiTableHeadCellProps={{            
            sx: {
              '& .Mui-TableHeadCell-Content': {
                "height": "30px"
              },
            },    
          }}
    />);
}