import { kicksharingAPITokenStoreInstance } from "KicksharingAPITokenStore";
import { apiUrl } from "config";
import { autorun, makeAutoObservable, runInAction } from "mobx";


export type AccessRights = {
    userID:string,
    userName: string,
    operatorUIAccess: boolean,
    scooterListing: boolean,
    zoneListing: boolean,
    movementTracksChart: boolean,
    rentListing: boolean,
    financialAnalysis: boolean,
    userContactDetailsAccess: boolean,
}


export class AccessRightsStore {
    public AccessRights:AccessRights|null = null;

    public constructor() {
        makeAutoObservable(this)
    }
}

export var accessRightsStoreInstance : AccessRightsStore = new AccessRightsStore();

autorun(async() => {
    if(kicksharingAPITokenStoreInstance.token && kicksharingAPITokenStoreInstance.confirmToken) {
          console.log("Getting access rights...");
          const accessReq = await fetch(`${apiUrl}/auth/access`, {
            headers: {
              Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
            },
          }).catch(e => console.error("Error during fetching access rights", e));
          if(accessReq) {
            if (!accessReq.ok) {
                console.error("Unsuccessful error code during fetching access rights",accessReq);
                return;
            }
            const access:AccessRights = await accessReq.json();
            runInAction(() => accessRightsStoreInstance.AccessRights = access)
            console.log("Successfully obtained access rights")
          }
        };
    })