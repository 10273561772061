import { apiUrl } from "config";
import { makeAutoObservable, autorun, runInAction, observable } from "mobx";
import {kicksharingAPITokenStoreInstance} from "KicksharingAPITokenStore";
import { citiesStoreInstance } from "CitiesStore";
import { deactivationTypesStoreInstance } from "DeactivationTypesStore";
import { accessRightsStoreInstance } from "AccessRightsStore";

export interface DeactivationJSON {
  comment?: string;
  id?: string;
  typeId?: string;
  startTime?: string;
  deactivatedBy?: string;
}


export type GnssString = "NOFIX" | "FIX" | "SLEEP" | "OFF"

export function GnssStringToTranslation(gnss:string) {
  switch(gnss) {
    case 'FIX': return "📡 координаты получены";
    case 'NOFIX': return "❌ нет видимости спутников";
    case 'SLEEP': return "💤 энергосбережение";
    case 'OFF': return "⛔ отключено";
    default:
      return gnss;
  }
}

interface RentableUnit {
  city: null;
  imei: number;
  id: string;
  scooterPublicID: string;
  model: string;
  online: boolean;
  ongoingRentID: string | null;
  deactivation: DeactivationJSON | null
  deactivationComment: string;
  latestSignalTime: string;
  type: string;
}

interface Vehicle extends RentableUnit {
  lat: number;
  lon: number;
  moving: boolean;
  throttleEnabled: boolean;
  isInAllowedZone: boolean;
  latestCoordTime: string;
}

/**
 * For now matches the JSON schema of received scooters list
 */
interface Scooter extends Vehicle {
  errorCode: number;
  gnss: GnssString;
  status: string;
  statusRent: string;
  locked: boolean;
  batteryChargePercentage: number;
}

interface Car extends Vehicle {
  doorOpenFL? :boolean;
  doorOpenFR? :boolean;
  doorOpenRR? :boolean;
  doorOpenRL? :boolean;
  windowOpenFL? :boolean;
  windowOpenFR? :boolean;
  windowOpenRR? :boolean;
  windowOpenRL? :boolean;
  hoodOpen? :boolean;
  trunkOpen? :boolean;
  doorsLocked?: boolean;
  seatBeltFL?: boolean;
  seatBeltFR?: boolean;
  handBrakeActive?: boolean;
  vin?: string;
}

interface VegaMtXCan extends Car {
  charging?: boolean;
  chargerConnected?: boolean;
  chargePercentage?: number;
  digitalOutput1?:boolean;
}

interface SelectOption {
  text: string;
  value: string;
}

export abstract class RentableUnitStoreItem {
  @observable id: string;
  @observable city: string;
  @observable imei: number;
  @observable scooterPublicID: string;
  @observable publicID: string;
  @observable model: string;
  @observable online: boolean;
  @observable ongoingRentID: string | null;
  @observable.deep deactivation: DeactivationJSON | null
  @observable deactivationComment: string;
  @observable latestSignalTime: string;
  @observable statusRent: string;
  @observable type: "Car" | "Scooter" | "Unknown";

  /**
   * Russian translation of the city where the scooter currently assigned to
   */
  get RuCity():string {
    if(this.city){
      var ruName = citiesStoreInstance.RussianNames[this.city];
      if(ruName)
        return ruName;
    }

    return this.city;
  }

  constructor(unit: RentableUnit) {
    this.id = unit.id;
    this.city = unit.city;
    this.imei = unit.imei;
    this.scooterPublicID = unit.scooterPublicID;
    this.publicID = unit.scooterPublicID;
    this.model = unit.model;
    this.online = unit.online;
    this.ongoingRentID = unit.ongoingRentID;
    this.deactivation = unit.deactivation;
    this.latestSignalTime = unit.latestSignalTime;
    this.deactivationComment = unit.deactivation?.comment ?? "";
    switch(unit.type) {
      case 'Car': this.type = 'Car'; break;
      case 'Scooter': this.type = 'Scooter'; break;
      default: this.type = 'Unknown'; break;
    }
    this.statusRent = this.ongoingRentID ? 'в аренде' : 'не в аренде';
  }
}

export abstract class VehicleStoreItem extends RentableUnitStoreItem {
  @observable lat: number;
  @observable lon: number;
  @observable latLon: [number,number]
  @observable moving: boolean;
  @observable throttleEnabled: boolean;
  @observable isInAllowedZone: boolean;
  @observable latestCoordTime: string;

  constructor(v: Vehicle) {
    super(v);
    this.lat = v.lat;
    this.lon = v.lon;
    this.latLon = [v.lat,v.lon];
    this.moving = v.moving;
    this.throttleEnabled = v.throttleEnabled;
    this.isInAllowedZone = v.isInAllowedZone;
    this.latestCoordTime = v.latestCoordTime;
  }
}

export class ScooterStoreItem extends VehicleStoreItem {
  @observable errorCode: number;
  @observable gnss: GnssString;
  @observable batteryChargePercentage: number;
  @observable locked: boolean;
  type: "Scooter";
  
  constructor(scooter: Scooter) {
    super(scooter);
    this.errorCode = scooter.errorCode;
    this.gnss = scooter.gnss;
    this.locked = scooter?.locked;
    this.batteryChargePercentage = scooter.batteryChargePercentage;
    this.type = "Scooter";
  }  
}

export abstract class CarStoreItem extends VehicleStoreItem {
  @observable doorOpenFL? :boolean;
  @observable doorOpenFR? :boolean;
  @observable doorOpenRR? :boolean;
  @observable doorOpenRL? :boolean;
  @observable windowOpenFL? :boolean;
  @observable windowOpenFR? :boolean;
  @observable windowOpenRR? :boolean;
  @observable windowOpenRL? :boolean;
  @observable hoodOpen? :boolean;
  @observable trunkOpen? :boolean;
  /** Central lock status */
  @observable doorsLocked?: boolean;
  @observable seatBeltFL?: boolean;
  @observable seatBeltFR?: boolean;
  @observable handBrakeActive?: boolean;
  @observable vin?: string;
  type: "Car";

  constructor(car: Car) {
    super(car);
    this.type = "Car";
    this.doorOpenFL = car.doorOpenFL;
    this.doorOpenFR = car.doorOpenFR;
    this.doorOpenRR = car.doorOpenRR;
    this.doorOpenRL = car.doorOpenRL;
    this.windowOpenFL = car.windowOpenFL;
    this.windowOpenFR = car.windowOpenFR;
    this.windowOpenRR = car.windowOpenRR;
    this.windowOpenRL = car.windowOpenRL;
    this.hoodOpen = car.hoodOpen;
    this.trunkOpen = car.trunkOpen;
    this.doorsLocked = car.doorsLocked;
    this.seatBeltFL = car.seatBeltFL;
    this.seatBeltFR = car.seatBeltFR;
    this.handBrakeActive = car.handBrakeActive;
    this.vin = car.vin;
  }
}

export class VegaMtXCanStoreItem extends CarStoreItem{
  @observable charging?: boolean;
  @observable chargerConnected?: boolean;
  @observable chargePercentage?: number;
  @observable immobilizerDisabled?: boolean;

  constructor(vega: VegaMtXCan) {
    super(vega);
    this.charging = vega.charging;
    this.chargerConnected = vega.chargerConnected;
    this.chargePercentage = vega.chargePercentage;
    this.immobilizerDisabled = vega.digitalOutput1;
  }
}

export class ScooterStore {
  private rentableItems = new Array<RentableUnitStoreItem>();
  /** How many UI components are using the store currently. If 0 periodic updates are disabled */
  public consumerCount:number = 0;

  public increaseConsumerCount(){
    this.consumerCount+=1;
  }

  public decreaseConsumerCount(){
    this.consumerCount-=1;
  }

  periodicRefreshTimer:ReturnType<typeof setInterval> | null = null

  constructor() {
    makeAutoObservable(this);
  }
  
  get filterSelectOptions():SelectOption[] {
    const options:SelectOption[] = [];
    options.push({ text: 'Активный', value: 'active' })
    options.push(...deactivationTypesStoreInstance
      .Ids.map((id) =>{return {value:id, text:deactivationTypesStoreInstance.RussianNames[id]}}))
    return options;
  }

  get renableUnits():RentableUnitStoreItem[] {
    return this.rentableItems;
  }

  setRentableUnits(units: RentableUnit[]) {
    this.rentableItems = units.map(e => {
      switch(e.type) {
        case 'Scooter': return new ScooterStoreItem(e as Scooter);
        case 'Car': return new VegaMtXCanStoreItem(e as VegaMtXCan);
        default: throw new Error(`Unknown rentable unit type ${e.type}`);
      }
    });
  }
}

export const scooterStoreInstance = new ScooterStore();

autorun(() => {
  if(
    scooterStoreInstance.consumerCount>0 &&
    kicksharingAPITokenStoreInstance.token &&
    kicksharingAPITokenStoreInstance.confirmToken &&
    accessRightsStoreInstance?.AccessRights?.rentListing
    ) {
      if(scooterStoreInstance.periodicRefreshTimer == null) {
        console.log("Starting periodic scooter fetch")

        const fetchRecentScooters = async function() {
          {
            try{
            const response = await fetch(`${apiUrl}/scooters/list`, {
              headers: {
                Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
              },
            });
            if (!response.ok) {
              console.error("Unsuccessful status code during scooters fetch", response)
            } else {
              const parsed:RentableUnit[] = await response.json()
              scooterStoreInstance.setRentableUnits(parsed);
              console.log(`Got ${parsed.length} scooters`);
            }
          }
          catch(e)
          {
            console.error("Error during scooter list refresh",e)
          }
        }
        }

        fetchRecentScooters()
        runInAction(() =>{
          scooterStoreInstance.periodicRefreshTimer = setInterval(fetchRecentScooters, 5000)
        })
    }
  } else if(scooterStoreInstance.periodicRefreshTimer != null) {
    console.log("Stopping periodic scooter refresh")
    clearInterval(scooterStoreInstance.periodicRefreshTimer)
    runInAction(() => {scooterStoreInstance.periodicRefreshTimer = null})
  }
})


