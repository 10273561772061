import React from "react";
import { Navigate, useParams } from "react-router-dom";
import "./ScooterInfo.css";
import {CarStoreItem, GnssStringToTranslation, ScooterStoreItem, VegaMtXCanStoreItem, VehicleStoreItem, scooterStoreInstance} from "ScootersStore";
import { observer } from "mobx-react-lite";
import ButtonStatus from "components/ButtonStatus/ButtonStatus";
import MapLink from "components/MapLink/MapLink";
import { formatDateTime } from "utils/dateTimeUtils";

const ScooterInfo: React.FC = observer(() => {
  const { scooterId } = useParams();

  const rentableUnitInfo = scooterStoreInstance.renableUnits.find(
    (item) => item.scooterPublicID === scooterId
  );

  

  var produceInfoCell = function(title:string, value:string) {
    if(value) {
      return (
        <div className="ScooterInfo__ceil">
          <div>{title}: </div>
          <div>{value}</div>
        </div>
      );
    } else
    return null;
  }

  var produceInfoCellForField = function(title:string, fieldName:string) {
    if(rentableUnitInfo) {
      return produceInfoCell(title, rentableUnitInfo[fieldName]);
    } else
    return null;
  }

  var produceBoolInfoCell = function(title:string, fieldName:string, trueString:string, falseString:string) {
    if(rentableUnitInfo && rentableUnitInfo[fieldName] !== undefined) {
      return (
        <div className="ScooterInfo__ceil">
          <div>{title}: </div>
          <div>{rentableUnitInfo[fieldName] ? trueString : falseString}</div>
        </div>
      );
    } else return null;
  }

  var produceBoolInfoCellIfTrue = function(title:string, fieldName:string, trueString:string) {
    if(rentableUnitInfo && rentableUnitInfo[fieldName] == true) {
      return (
        <div className="ScooterInfo__ceil">
          <div>{title}: </div>
          <div>{trueString}</div>
        </div>
      );
    } else return null;
  }

  let throttleCell = null;
  let charge:number = null;
  let carTightnessCells:Array<JSX.Element> = []
  switch(rentableUnitInfo?.type){
  case "Car":
    const car = rentableUnitInfo as CarStoreItem;
    throttleCell = produceBoolInfoCell("Двигатель","throttleEnabled","🔑 Включен","Выключен");
    const openDoors:Array<string> = []
    if (car.doorOpenFL) openDoors.push("Передняя левая")
    if (car.doorOpenFR) openDoors.push("Передняя правая")
    if (car.doorOpenRL) openDoors.push("Задняя левая")
    if (car.doorOpenRR) openDoors.push("Задняя правая")
    const openWindows:Array<string> = []
    if (car.windowOpenFL) openWindows.push("Передняя левая")
    if (car.windowOpenFR) openWindows.push("Передняя правая")
    if (car.windowOpenRL) openWindows.push("Задняя левая")
    if (car.windowOpenRR) openWindows.push("Задняя правая")
    if (openDoors.length > 0) {
      carTightnessCells.push(produceInfoCell("Открытые двери", openDoors.join(", ")))
    }
    if (openWindows.length > 0) {
      carTightnessCells.push(produceInfoCell("Открытые окна", openWindows.join(", ")))
    }
    if (car.hoodOpen) {
      carTightnessCells.push(produceInfoCell("Капот", "❗ Открыт"))
    }
    if (car.trunkOpen) {
      carTightnessCells.push(produceInfoCell("Багажник", "⚠ Открыт"))
    }
    if (carTightnessCells.length == 0) {
      carTightnessCells.unshift(produceInfoCell("Окна и двери", "✅ Закрыты"))
    }

    const vega = car as VegaMtXCanStoreItem;
    if (vega.chargePercentage)
      charge = vega.chargePercentage;
    break;
  case "Scooter":
    throttleCell = produceBoolInfoCell("Ручка газа","throttleEnabled","Работает","Отключена");
    const scooter = rentableUnitInfo as ScooterStoreItem;
    if(scooter.batteryChargePercentage)
      charge = scooter.batteryChargePercentage;
    break;
  }

  var mapLink = null;
  if (rentableUnitInfo instanceof VehicleStoreItem) {
    mapLink = (
    <div className="ScooterInfo__ceil">
        <div>Координаты: </div>
        <div>
          <MapLink lat={rentableUnitInfo?.lat} lng={rentableUnitInfo?.lon} />
        </div>
    </div>)
  }

  return (
    <div className="ScooterInfo__container">
      {produceInfoCellForField("Город", "city")}
      {produceInfoCellForField("Модель", "model")}
      {produceInfoCellForField("VIN", "vin")}
      {produceInfoCellForField("ID", "id")}
      {produceInfoCellForField("IMEI", "imei")}
      {produceInfoCell("Заряд", charge ? charge.toString() + "%" : null)}
      <div className="ScooterInfo__ceil">
        <div>Статус: </div>
        <div>
          <ButtonStatus
            status={rentableUnitInfo?.online ? "в сети" : "не в сети"}
          ></ButtonStatus>
        </div>
      </div>      
      <div className="ScooterInfo__ceil">
        <div>Статус аренды: </div>
        <div>
          <ButtonStatus
            status={
              rentableUnitInfo?.statusRent === "в аренде"
                ? "в аренде"
                : "не в аренде"
            }
          ></ButtonStatus>
        </div>
      </div>
      {produceInfoCell("Геолокация",GnssStringToTranslation((rentableUnitInfo as ScooterStoreItem)?.gnss))}
      {throttleCell}
      {produceBoolInfoCell("Движение","moving","Движется","Стоит на месте")}
      {produceBoolInfoCellIfTrue("Ремень водителя","seatBeltFL","👌 Пристегнут")}
      {produceBoolInfoCellIfTrue("Ремень пассажира","seatBeltFR","👌 Пристегнут")}
      {produceBoolInfoCell("Центральный замок","doorsLocked","🔒 Закрыт","🔓 открыт")}
      {carTightnessCells}
      {produceBoolInfoCell("Обход иммобилайзера","immobilizerDisabled","‼🔑 Включен","✅ Выключен")}
      {produceBoolInfoCell("Блокировка","locked","🔒 Заблокирован","🔓 Разблокирован")}
      {produceBoolInfoCell("Положение","isInAllowedZone","✅ В разрешенной зоне","⛔ В запрещенной зоне")}
      {produceBoolInfoCellIfTrue("Заряжается","charging","⚡ Да")}
      {produceBoolInfoCellIfTrue("Подключаение к зарядке","chargerConnected","🔌 Вилка подключена")}
      {produceBoolInfoCellIfTrue("Стояночный тормоз","handBrakeActive","🅿 Включен")}
      
      {produceInfoCell("Время последнего сигнала", formatDateTime(rentableUnitInfo?.latestSignalTime))}
      {produceInfoCell("Время обновления координат",formatDateTime((rentableUnitInfo as VehicleStoreItem)?.latestCoordTime))}
      {mapLink}

      <div
        className="ScooterInfo__ceil"
        onClick={() => {
          window.location.href += "/history";
        }}
      >
        <div className="ScooterInfo__button">История</div>
      </div>
    </div>
  );
});

export default ScooterInfo;
