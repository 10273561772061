import React from "react";
import App from "./App";
import { createRoot } from "react-dom/client";
import { isDev } from "config";

document.addEventListener("DOMContentLoaded", () => {
  const icon = document.querySelector('link[rel="icon"]');
  icon.setAttribute("href", isDev ? "/favicon_dev.ico" : "/favicon.ico");
  if (isDev) {
    document.title = "[DEV] " + document.title;
  }
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
