import React,{FC} from 'react';
import './Button.css';

type ButtonProps = {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
};

const Button: FC<ButtonProps> = ({ text, onClick, disabled }) => {
  return (
    <button className={disabled ? 'button button_disabled' : 'button'} type="submit" disabled={disabled}>
        {text}
    </button>
  );
};

export default Button;
