import React, { useState } from 'react';
import {kicksharingAPITokenStoreInstance} from 'KicksharingAPITokenStore';
import {scooterStoreInstance} from 'ScootersStore';
import { useParams } from 'react-router-dom';
import './ScooterDeactivation.css';
import ButtonCommand from 'components/ButtonCommand/ButtonCommand';
import { Dictionary, deactivationDict } from 'deactivationDict';
import Button from '@mui/material/Button';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Stack, ThemeProvider, capitalize, createTheme } from '@mui/material';
import { apiUrl } from 'config';
import { CancelDeactivation } from 'api';
import { type } from '@testing-library/user-event/dist/type';
import { observer } from 'mobx-react-lite';
import { useVehicleTypeNames } from 'hooks/useVehicleTypeString';

export type HiddenDialogState = {
    state: "hidden"
}

export type InProgressDialogState = {
    state: "inProgress"
    message: string
}

export type SuccessDialogState = {
    state: "success"
    message: string
}

export type ConfirmDialogState = {
    state: "confirm"
    message: string
}


export type ErrorDialogState = {
    state: "error"
    message: string
}

export type DialogState = ConfirmDialogState | HiddenDialogState | InProgressDialogState | SuccessDialogState | ErrorDialogState

const ScooterDeactivation: React.FC = observer(() => {
    const [comment, setComment] = useState<string>('');
    const { scooterId: unitId } = useParams<{ scooterId: string }>();
    const [dialogState, setDialogState] = useState<DialogState>({state: "hidden"});

    const item = scooterStoreInstance.renableUnits.find(item => item.publicID == unitId);
    const deactivation = item?.deactivation

    const vehicleCases = useVehicleTypeNames(item?.type);

    const deactivate = (deactivationType: string) => {
        const scooterData = scooterStoreInstance.renableUnits.find(item => item.scooterPublicID === unitId);
        const scooterIdCom = scooterData.id;
        const deactivationTranslation = deactivationDict[deactivationType] ?? deactivationType;

        setDialogState({state: "inProgress", message: `Деактивирую ${vehicleCases.nominative} ${unitId} в состояние "${deactivationTranslation}"...`})
        
        fetch(`${apiUrl}/scooters/${scooterIdCom}/deactivation?deactivationType=${deactivationType}&comment=${comment}`, {
            method: 'PUT',
            headers: {
                Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to deactivate scooter');
                }
                setDialogState({
                    state: "success",
                    message: `${capitalize(vehicleCases.nominative)} ${unitId} деактивирован в состояние "${deactivationTranslation}"`,
                })
            })
            .catch(error => {
                console.error('Error:', error);
                setDialogState({
                    state: "error",
                    message: `Не удалось деактивировать ${vehicleCases.nominative} ${unitId} в состояние "${deactivationTranslation}". Ошибка ${error}}`
                })
            });
    };

    const cancelDeactivation = () => {
        setDialogState({state: "inProgress", message: `Отменяю деактивацию ${vehicleCases.genitive} ${unitId}...`})
        const scooterData = scooterStoreInstance.renableUnits.find(item => item.publicID == unitId);
        const scooterIdCom = scooterData.id;
        
        CancelDeactivation(scooterIdCom).then((success) => {
            if(success){
                setDialogState({state: "success", message: `Деактивация ${vehicleCases.genitive} ${unitId} отменена`})
            }else{
                setDialogState({state: "error", message: `Не удалось отменить деактивацию ${vehicleCases.genitive} ${unitId}`})
            }
        }).catch((error) => {
            setDialogState({state: "error", message: `Не удалось отменить деактивацию ${vehicleCases.genitive} ${unitId}. Ошибка ${error}`})
        })
    };

    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
    };

    const handleClose = () => {
        setDialogState({state: "hidden"});
    };

    let dialogTitle: JSX.Element = null;
    let dialogText:string = "";
    let dialogActions: JSX.Element = null;
    switch(dialogState.state){
        case "inProgress":
            dialogTitle = <DialogTitle>Подождите...</DialogTitle>;
            dialogText = dialogState.message;
            break;
        case "success":
            dialogTitle = <DialogTitle>Успешно ✅</DialogTitle>;
            dialogText = dialogState.message;
            dialogActions = <Button onClick={handleClose}>Закрыть</Button>;
            break;
        case "error":
            dialogTitle = <DialogTitle>Ошибка ❌</DialogTitle>;
            dialogText = dialogState.message;
            dialogActions = <Button onClick={handleClose}>Закрыть</Button>;
            break;
    }

    return (
        <div className='ScooterDeactivation__container'>
            <Dialog
                open={dialogState.state != "hidden"}
                onClose={handleClose}
                >
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={2}>
                        {dialogText}
                        {dialogState.state == "inProgress" && <LinearProgress />}
                    </Stack>
                    </DialogContent>
                <DialogActions>
                    {dialogActions}
                </DialogActions>
            </Dialog>
            <h3 className='ScooterDeactivation__title'>Деактивировать со статусом</h3>
            <div className='ScooterDeactivation__comment'>
                <input type='text' value={comment} onChange={handleCommentChange} placeholder='Комментарий' />
            </div>
            <div className='ScooterDeactivation__commands'>
                {Object.entries(deactivationDict).map(([key, value]) => (
                    <ButtonCommand key={key} buttonText={value} handleButtonClick={() => deactivate(key)} />
                ))}
                {
                    (deactivation != null) &&
                    <ButtonCommand key="cancel" additionalCssClass='ButtonCommand_back' buttonText="Отменить деактивацию" handleButtonClick={() => cancelDeactivation()} />
                }
            </div>
        </div>
    );
})

export default ScooterDeactivation;