import zonesStore from 'ZonesStore';
import { PathOptions } from 'leaflet';
import { observer } from 'mobx-react-lite';
// import { observer } from 'mobx-react-lite';
import React from 'react';
import {Polygon} from 'react-leaflet';


const Polygons: React.FC  = observer(() => {

  function getPathOptions(typeZones: string): PathOptions {
    switch (typeZones) {
      case 'Allowed':
        return { fillColor: 'green', color: 'green' };
      case 'Restricted':
        return { fillColor: 'red', color: 'red' };
      case 'SpeedLimit':
        return  { fillColor: 'yellow', color: 'yellow' }
      case 'Parking':
        return  { fillColor: ' #2F80ED', color: '#2F80ED' }
      default:
        return  { fillColor: ' gray', color: 'gray' }
    }
  }

  return (
    <div>
    {zonesStore.zones.map((zone) => (
      <Polygon 
      key={zone.name} 
      positions={zone.geometry.coordinates[0].map(([lat, lng]) => [lng, lat])} 
      pathOptions={getPathOptions(zone.kind)} 
      />
    ))}

    </div>
);
      });
export default Polygons;
