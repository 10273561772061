import React, { useState, useEffect } from 'react';
import Button from 'components/Button/Button';
import '../auth/auth.css';
import { useNavigate } from 'react-router-dom';
import {kicksharingAPITokenStoreInstance} from 'KicksharingAPITokenStore';
import { apiUrl } from 'config';
import { Snackbar, SnackbarContent } from '@mui/material';

const Auth: React.FC = () => {
  const navigate = useNavigate();
  const [phoneNum, setPhoneNumber] = useState('');
  const [formComplete, setFormComplete] = useState(false);
  const [phoneNumError, setPhoneNumError] = useState('');
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string }>({
    open: false,
    message: '',
});
  useEffect(() => {
    const tel = phoneNum;
    const isComplete = tel.trim() !== '' && phoneNumError === '';
    setFormComplete(isComplete);

    if (tel.trim() === '') {
      setPhoneNumError('Пустое поле');
    }
  }, [phoneNum, phoneNumError]);

  const api = `${apiUrl}/auth/generateToken?`;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  try {
    const response = await fetch(api + new URLSearchParams({phoneNum: phoneNum.toString()}), {
      method: 'POST'
    });
    if (response.ok) {
      const token = await response.text();
      kicksharingAPITokenStoreInstance.setToken(token);
      navigate('/verification');
    } else {
      throw new Error('Ошибка при получении токена');
    }
  } catch (error) {
    setSnackbar({
      open: true,
      message: 'Слишком частые запросы'
    });
    setTimeout(() => {
      setPhoneNumber('');
    }, 1000);
  }
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.trim() === '') {
      setPhoneNumError('Пустое поле');
    } else if (!/^(\+7|7)\d{10}$/.test(value)) {
      setPhoneNumError('Неверный формат. Пример: +7(999)999 99-99');
    } else {
      setPhoneNumError('');
    }
    setPhoneNumber(value);
  };

  return (
    <div className="Auth__container">
      <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <SnackbarContent
                    className='Auth__snackbar'
                    message={snackbar.message}
                    style={{ backgroundColor: '#EB5757' }}
                />
            </Snackbar>
      <form className="Auth__form" onSubmit={handleSubmit}>
        <p className="Auth__title">Авторизация</p>
        <label className="Auth__phone">Номер телефона</label>
        <input
          type="tel"
          className={`Auth__input ${phoneNumError ? 'Auth__input--error' : ''}`}
          placeholder="+7 (___) ___ __ - __"
          value={phoneNum}
          onChange={handlePhoneNumberChange}
        />
        {phoneNumError && (
          <div className="Auth__error">{phoneNumError}</div>
        )}
        <div className="Auth__button">
          <Button text="Авторизация" disabled={!formComplete} />
        </div>
      </form>
    </div>
  );
};

export default Auth;