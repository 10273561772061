import React, { useEffect, useMemo, useState } from 'react';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import {scooterStoreInstance, DeactivationJSON, ScooterStoreItem, GnssStringToTranslation, VehicleStoreItem, VegaMtXCanStoreItem, RentableUnitStoreItem } from 'ScootersStore';
import NavigationMenu from 'components/NavigationMenu/NavigationMenu';
import { Link } from 'react-router-dom';
import { Observer, observer } from 'mobx-react-lite';
import { autorun, reaction, runInAction } from 'mobx';
import ButtonStatus from 'components/ButtonStatus/ButtonStatus';
import './ScooterList.css';
import MapLink from 'components/MapLink/MapLink';
import { useFreshScooterData } from 'hooks/useFreshScooterData';

const ScooterList = observer(() => {
  
  useFreshScooterData()

  
  const columns :MRT_ColumnDef<RentableUnitStoreItem>[] = [
      {
        accessorKey: 'publicID',
        header: 'Номер',
        muiTableHeadCellFilterTextFieldProps: {
          placeholder: '0000'
        },
        size: 50,
        Cell: ({ cell }) => {
          return <Link to={`/scooters/${cell.getValue<string>()}`}>{`${cell.getValue<string>()}`}</Link>;
        }
      },
      {
        accessorKey: 'id',
        header: 'DataBase ID',
        muiTableHeadCellFilterTextFieldProps: {
          placeholder: 'ID'
        }
      },
      {
        accessorKey: 'imei',
        header: 'IoT IMEI',
        muiTableHeadCellFilterTextFieldProps: {
          placeholder: 'IMEI'
        }
      },
      {
        id:"latestCoordTime",
        accessorFn: (row) => (row as VehicleStoreItem)?.latestCoordTime,
        header: 'Последняя передача координат',
        enableColumnFilter:false
      },
      {
        id:"latestSignalTime",
        accessorKey: 'latestSignalTime',
        header: 'Последняя передача телеметрии',
        enableColumnFilter:false
      },
      {
        id:"locked",
        accessorFn: (row) => (row as ScooterStoreItem)?.locked,
        header: 'Блокировка',
        Cell: ({ cell }) => {
          return cell.getValue<boolean>() ? '🔒 Заблокирован ' : '🔓 Разблокирован';
        },
        filterFn: 'equals',
        filterSelectOptions: [
          { text: '🔒 Заблокирован', value: true },
          { text: '🔓 Разблокирован', value: 'false' },
        ],
        filterVariant: 'select',
      },
      {
        id:"throttleEnabled",
        accessorFn: (row) => (row as VehicleStoreItem)?.throttleEnabled,
        header: 'Ручка газа',
        Cell: ({ cell }) => {
          return cell.getValue<boolean>() ?  'Работает' : 'Отключена';
        },
        filterFn: 'equals',
        filterSelectOptions: [
          { text: 'Работает', value: true },
          { text: 'Отключена', value: 'false' },
        ],
        filterVariant: 'select',
      },
      {
        id:"isInAllowedZone",
        accessorFn: (row) => (row as VehicleStoreItem)?.isInAllowedZone,
        header: 'В разрешенной зоне',
        Cell: ({ cell }) => {
          return cell.getValue<boolean>() ? '✅ в зоне' : '⛔ вне зоны';
        },
        filterFn: 'equals',
        filterSelectOptions: [
          { text: '✅ в зоне', value: true },
          { text: '⛔ вне зоны', value: 'false' },
        ],
        filterVariant: 'select',
      },
      {
        id:"moving",
        accessorFn: (row) => (row as VehicleStoreItem)?.moving,
        header: 'Движется',
        Cell: ({ cell }) => {
          return cell.getValue<boolean>() ? 'Движется ' : 'Стоит';
        },
        filterFn: 'equals',
        filterSelectOptions: [
          { text: 'Движется', value: true },
          { text: 'Стоит', value: 'false' },
        ],
        filterVariant: 'select',
      },
      {
        id:"latLon",
        accessorFn: (row) => (row as VehicleStoreItem)?.latLon,
        header: 'Ссылка на карту',
        maxSize: 30,
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          return <MapLink lat={cell.getValue<number>()[0]} lng={cell.getValue<number>()[1]} />;
        }
      },
      {
        id:"errorCode",
        accessorFn: (row) => (row as ScooterStoreItem)?.errorCode,
        header: 'Код ошибки',
        muiTableHeadCellFilterTextFieldProps: { placeholder: '0' },
      },
      {
        id:"gnss",
        accessorFn: (row) => (row as ScooterStoreItem)?.gnss,
        header: 'Геолокация',
        filterFn: 'equals',        
        filterVariant: 'select',
        filterSelectOptions: [
          { text: GnssStringToTranslation("FIX"), value: "FIX" },
          { text: GnssStringToTranslation("NOFIX"), value: "NOFIX" },
          { text: GnssStringToTranslation("SLEEP"), value: "SLEEP" },
          { text: GnssStringToTranslation("OFF"), value: 'OFF' },
        ],
        Cell: ({ cell }) => GnssStringToTranslation(cell.getValue<string>())
      },
      {
        id:"batteryChargePercentage",
        accessorFn: (row) =>
          (row.type == "Scooter") ?
          (row as ScooterStoreItem)?.batteryChargePercentage :
          (row as VegaMtXCanStoreItem)?.chargePercentage,
        maxSize: 40,
        header: 'Заряд',
        filterVariant: 'range',
      },
      {
        id: 'deactivation',
        accessorFn: (row) => row.deactivation?.typeId ?? 'active', // workaround, as pure null value brakes Material react table
        header: 'Статус',
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'Деактивация' },
        filterFn: 'equals',
        filterVariant: 'select',
        filterSelectOptions: scooterStoreInstance.filterSelectOptions,
        Cell: ({ cell }) => {
          if(scooterStoreInstance.filterSelectOptions.length == 0)
            return "загрузка...";

          var cellVal = cell.getValue<string>()

          return <>
          {
            scooterStoreInstance
            .filterSelectOptions
            .find(option => option.value === cellVal)?.text ?? cellVal
          }</>
        },
      },
      {
        accessorKey: 'online',
        header: 'В сети/ Не в сети',
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'в сети' },
        maxSize: 30,
        Cell: ({ cell }) => {
          return <ButtonStatus status={cell.getValue<boolean>() ? 'в сети' : 'не в сети'}></ButtonStatus>
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        filterFn: 'equals',
        filterSelectOptions: [
          { text: 'в сети', value: true },
          { text: 'не в сети', value: 'false' },
        ],
        filterVariant: 'select',
      },
      {
        accessorKey: 'statusRent',
        header: 'Аренда',
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'аренда' },
        maxSize: 30,
        Cell: ({ cell }) => {
          return <ButtonStatus status={cell.getValue<string>() === 'в аренде' ? 'в аренде' : 'не в аренде'}></ButtonStatus>
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        filterFn: 'equals',
        filterSelectOptions: [
          { text: 'в аренде', value: 'в аренде' },
          { text: 'не в аренде', value: 'не в аренде' },
        ],
        filterVariant: 'select',
      },
      {
        accessorKey: 'city',
        header: 'Город',
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'Moscow' },
        muiTableBodyCellProps: {
          align: 'center',
        },
        filterVariant: 'text'
      },
      {
        accessorKey: 'model',
        header: 'Модель',
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'модель' },
      }
    ];  

  return (<div>
    <NavigationMenu></NavigationMenu>
    <div className="ScooterList__container">
      <h2 className="ScooterList__title">Транспорт</h2>
          <MaterialReactTable
            columns={columns}
            data={scooterStoreInstance.renableUnits}
            initialState={{
              showColumnFilters: true,
              columnVisibility: { model: false, map:false, imei: false, latLon:false,
                id: false, latestCoordTime: false, latestSignalTime: false,
                gnss: false,
                locked: false, moving: false, throttleEnabled:false, isInAllowedZone: false,
                errorCode:false
               },
              density: 'compact',}}
            enableRowNumbers={true}
            rowNumberMode="static"
            enablePagination={false}            
            muiTableHeadCellFilterTextFieldProps={{
              sx: {
                width: '80%',
                borderRadius: '8px'
              },
              variant: 'outlined',
            }}
            muiTablePaperProps={
              {
                sx: {
                  boxShadow: 'none',
                },
              }
            }
            muiTableContainerProps={
              {
                sx: {
                  border: '1px solid rgba(12, 58, 120, 0.3)',
                }
              }
            }
          />
    </div>
  </div>);
});

export default ScooterList;
