import React, { useEffect, useMemo } from "react";
import * as Router from "react-router-dom";

import * as GeoMap from "react-leaflet";
import * as LeafLet from "leaflet";

import { observer } from "mobx-react-lite";

import * as UI from "@mui/material";
import * as Icons from "@mui/icons-material";
import NavigationMenu from "components/NavigationMenu/NavigationMenu";

import dayjs, { Dayjs } from "dayjs";
import { apiUrl } from "config";
import { kicksharingAPITokenStoreInstance } from "KicksharingAPITokenStore";

import localizedFormat from "dayjs/plugin/localizedFormat";
import localeData from "dayjs/plugin/localeData";
import ru from "dayjs/locale/ru";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

import "./style.css";
import { useRentableUnitCommands } from "hooks/useRentableUnitCommands";

import * as Legacy from "components/Polygons/Polygons";

dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.locale("ru");

type CommandItem = {
  userId: string;
  rentId?: string;
  scooterId: string;
  whenUTC: string;
  durationMs: number;
  badgeUser?: string;
  command: string;
  result: string;
};

type TrackItem = {
  time: string;
  lat: number;
  lon: number;
};

type RentItem = {
  rentID?: string;
  continuationRentID?: string; //
  continuationOfID?: string; //
  fare: string;
  status: "Starting" | "Active" | "Ending" | "LockedOutOfZone" | "Terminated";
  startTime?: string;
  paid?: number; //
  rideEndTime?: string;
  totalDistance?: number;
  terminationReason?:
  | "ExplicitEndByUser"
  | "TimeOut"
  | "CantUnlockScooter"
  | "CantAcquirePayment"
  | "CantSecureInsurance"
  | "InsufficientFunds";
  scooterID: string;
  city: string;
  createdAt: string;
  createdBy?: string;
  badgeCreatedBy?: string; // internal field
  terminatedBy?: string;
  badgeTerminatedBy?: string; // internal field
  terminatedAt?: string;
  errorMessage?: string;
  payments: PaymentItem[];
  insurancePolicyID?: string;
  insurancePolicyLink?: string;
  userAgent?: string;
};

type PaymentItem = {
  rentID: string;
  transaction: number;
  status: "Paid" | "Authorized" | "Cancelled"; // string;
  invoiceID: string;
  amountKopecks: number;
  issuedAt?: number;
  authorizedAt?: number;
  completeAt?: number;
  receiptLink?: string;
  returnReceiptLink?: string;
  isDeposit?: true;
};

type DeactivationItem = {
  id: string;
  startTime: string;
  endTime?: string;
  comment?: string;
  typeId?: string;
  deactivatedBy?: string;
  badgeDeactivatedBy?: string;
  removedDeactivationBy?: string;
  badgeRemovedDeactivationBy?: string;
  scooterId?: string;
};

type DeactivationTypeItem = {
  id: string;
  ruName: string;
};

type Dataset = {
  rents: RentItem[];
  track: TrackItem[];
  commands: CommandItem[];
  deactivations: DeactivationItem[];
};

type StatusType = "empty" | "loading" | "ready" | "error";

type UserData = {
  id: string;
  name: string;
  email: string;
  successfulRentsCount: number;
  insufficientFundsRentsCount: number;
  registeredAt: string;
  deletedAt?: string;
  phoneNum: number;
};

type ShareLinkState = {
  lat: number;
  lng: number;
  zoom: number;
  fromTime: Dayjs;
  toTime: Dayjs;
  nowSlider: number;
  maxSlider: number;
  isSharedLinkView: boolean
}

function reduceTrackPointsCount(track: TrackItem[], maxPoints: number) {
  if (track.length <= maxPoints)
    return track;
  const step = Math.floor(track.length / maxPoints);
  const result = track.filter((_, i) => i % step === 0);
  result.push(track[track.length - 1]);
  return result;
}

function useSharedLinkState(): ShareLinkState {
  return useMemo(() => {
    const [link, position] = window.location.hash.split("state=");
    if (position) {
      window.location.replace(link);
      const [latStr, lonStr, zoom, fromTime, toTime, nowSlider, maxSlider] =
        position.split(",");

      return {
        lat: Number(latStr),
        lng: Number(lonStr),
        zoom: Number(zoom),
        fromTime: dayjs(fromTime),
        toTime: dayjs(toTime),
        nowSlider: Number(nowSlider),
        maxSlider: Number(maxSlider),
        isSharedLinkView: true
      };
    } else {
      // TODO: load the city of the scooter, add scooter dependency
      return {
        lat: 54.72847081143663,
        lng: 37.17888236045838,
        zoom: 13,
        fromTime: dayjs().startOf("day"),
        toTime: dayjs().startOf("day").add(1, "day"),
        nowSlider: 0,
        maxSlider: 0,
        isSharedLinkView: false
      };
    }
  }, []);
}

const usersCache = new Map<string, UserData>();

type Track = { track: Array<[number, number]>; inRent: boolean }

type SliderMark = { value: number; label?: string }

type Log = {
  storyLog: React.JSX.Element[],
  tracks: Array<Track>,
  sliderMarks: Array<SliderMark>
}

type MapMarker = {
  markerPosition: [number, number],
  markerDate: Dayjs,
  markerState: {
    rentIds: string[];
    deactivateIds: string[];
  }
}

export const ScooterHistory = observer(() => {
  const unlockLineView = {
    color: "green",
    opacity: 0.8,
    weight: 3,
  };
  const lockLineView = {
    color: "red",
    opacity: 0.8,
    weight: 3,
  };

  const initialState = useSharedLinkState() // TODO: replace with {zoom, ... }

  const { scooterId } = Router.useParams();
  const navigate = Router.useNavigate();

  const commandDefinitions = useRentableUnitCommands(scooterId)

  const [currentScooterId, setCurrentScooterId] =
    React.useState<string>(scooterId);

  React.useEffect(() => {
    if (currentScooterId.length !== 4) {
      return;
    }
    if (currentScooterId != scooterId) {
      navigate(`/scooters/${currentScooterId}/history`);
      setStatus("empty");
    }
  }, [currentScooterId]);

  const [map, setMap] = React.useState(null);

  const initialCenter: LeafLet.LatLngLiteral = useMemo(() => {
    return {
      lat: initialState.lat,
      lng: initialState.lng,
    }
  }, [initialState]);

  // const defaultFromTime = dayjs().startOf("day");
  // const defaultToTime = defaultFromTime.add(1, "day");

  const [fromTime, setFromTime] = React.useState<Dayjs>(initialState.fromTime);
  const [toTime, setToTime] = React.useState<Dayjs>(initialState.toTime);

  const timeRangeMs = useMemo(() => toTime.diff(fromTime), [fromTime, toTime])
  const sliderStepMs = useMemo(() => {
    if (timeRangeMs > 1000 * 60 * 60 * 24 * 7) // more than week
      return 1000 * 60 * 60 // one hour => > 168 slider stops
    if (timeRangeMs > 1000 * 60 * 60 * 24) // more than a day
      return 1000 * 60 // one minute => > 1440 slider stops
    if (timeRangeMs > 1000 * 60 * 60) // more than an hour
      return 1000 * 10 // ten seconds => > 360 slider stops
    return 1000 // one second => up to 3600 slider stops
  }, [timeRangeMs])

  const [status, setStatus] = React.useState<StatusType>("empty");
  const [error, setError] = React.useState<string>("");

  const [dataset, setDataset] = React.useState<Dataset>({
    rents: [],
    track: [],
    commands: [],
    deactivations: [],
  });

  const [deactivationTypes, setDeactivationTypes] = React.useState<
    DeactivationTypeItem[]
  >([]);

  const [infoBoxContent, setInfoBox] = React.useState<string>("");

  const [sliderPosition, setSliderPosition] = React.useState<number>(0);

  const [sliderMax, setSliderMax] = React.useState<number>(0);

  const [selectedUser, setSelectedUser] = React.useState<UserData | string>(
    undefined
  );
  const [selectedDeactivation, setSelectedDeactivation] = React.useState<
    DeactivationItem | string
  >(undefined);

  const [selectedRent, setSelectedRent] = React.useState<RentItem | string>(
    undefined
  );

  const [selectedPayment, setSelectedPayment] = React.useState<
    PaymentItem | string
  >(undefined);

  async function requestRents() {
    const query = new URLSearchParams({
      fromTime: fromTime.toISOString(),
      toTime: toTime.toISOString(),
      expand: true.toString(),
      onlyFinished: false.toString(),
      onlyForScooters: currentScooterId,
    }).toString();

    const request = await fetch(`${apiUrl}/rents?${query}`, {
      headers: {
        Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
      },
    });

    const json = await request.json();

    const actions = [];
    for (const item of json) {
      if (item.terminatedBy) {
        actions.push(
          userIdToBadge(item.terminatedBy).then((badge) => {
            item.badgeTerminatedBy = badge;
          })
        );
      }
      if (item.createdBy) {
        actions.push(
          userIdToBadge(item.createdBy).then((badge) => {
            item.badgeCreatedBy = badge;
          })
        );
      }
    }
    await Promise.allSettled(actions);

    return json;
  }

  async function requestDeactivations() {
    const query = new URLSearchParams({
      fromTime: fromTime.toISOString(),
      toTime: toTime.toISOString(),
    }).toString();

    const request = await fetch(
      `${apiUrl}/scooters/${currentScooterId}/deactivations?${query}`,
      {
        headers: {
          Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
        },
      }
    );

    const json = await request.json();

    const actions = [];
    for (const item of json) {
      if (item.deactivatedBy) {
        actions.push(
          userIdToBadge(item.deactivatedBy).then(
            (badge) => (item.badgeDeactivatedBy = badge)
          )
        );
      }
      if (item.removedDeactivationBy) {
        actions.push(
          userIdToBadge(item.removedDeactivationBy).then(
            (badge) => (item.badgeRemovedDeactivationBy = badge)
          )
        );
      }
    }
    await Promise.allSettled(actions);

    return json;
  }

  async function requestTrack() {
    const query = new URLSearchParams({
      startTime: fromTime.toISOString(),
      endTime: toTime.toISOString(),
    }).toString();

    const request = await fetch(
      `${apiUrl}/scooters/${currentScooterId}/track?${query}`,
      {
        headers: {
          Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
        },
      }
    );
    if (request.status === 404) {
      throw new Error("Scooter not found");
    }

    return await request.json();
  }

  async function requestCommands() {
    const query = new URLSearchParams({
      scooterIdOrName: currentScooterId,
      fromUTC: fromTime.toDate().toISOString(),
      toUTC: toTime.toDate().toISOString(),
    }).toString();
    const request = await fetch(
      `${apiUrl}/scooters/${currentScooterId}/commandLog?${query}`,
      {
        headers: {
          Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
        },
      }
    );
    if (request.status === 404) {
      throw new Error("Scooter not found");
    }

    const json = await request.json();

    await Promise.allSettled(
      json
        .filter((item) => item.userId)
        .map(async (item) => {
          item.badgeUser = await userIdToBadge(item.userId);
        })
    );

    return json;
  }

  async function userIdToBadge(userId: string) {
    if (!usersCache.has(userId)) {
      usersCache.set(userId, await requestUser(userId));
    }
    const user = usersCache.get(userId);
    if (!user) {
      return getShortId(userId);
    } else if (user.name) {
      return processName(user.name);
    } else if (user.phoneNum) {
      return formatPhone(user.phoneNum);
    } else {
      return getShortId(userId);
    }
  }

  function userIdToName(userId: string) {
    const user = usersCache.get(userId);
    if (!user) {
      return userId;
    } else if (user.name) {
      return user.name;
    } else if (user.phoneNum) {
      return formatPhone(user.phoneNum);
    } else {
      return userId;
    }
  }

  async function requestUser(userId: string) {
    const request = await fetch(`${apiUrl}/profiles/${userId}`, {
      headers: {
        Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
      },
    });
    const json = await request.json();

    return json;
  }

  async function requestDeactivationTypes() {
    const request = await fetch(`${apiUrl}/deactivations/types`, {
      headers: {
        Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
      },
    });

    return await request.json();
  }

  async function requestDataset() {
    if (deactivationTypes.length === 0) {
      setDeactivationTypes(await requestDeactivationTypes());
    }
    let rents = [];
    let track = [];
    let commands = [];
    let deactivations = [];
    try {
      [rents, track, commands, deactivations] = await Promise.all([
        requestRents(),
        requestTrack(),
        requestCommands(),
        requestDeactivations(),
      ]);
    } catch (e) {
      throw e;
    } finally {
      // console.log(track.length," track points")
      setDataset({
        rents: rents,
        track: reduceTrackPointsCount(track, 500),
        commands: commands,
        deactivations: deactivations,
      });
    }
  }

  async function userRequest() {
    if (typeof selectedUser !== "string") {
      return;
    }
    try {
      const request = await fetch(`${apiUrl}/profiles/${selectedUser}`, {
        headers: {
          Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
        },
      });

      if (typeof selectedUser !== "string") {
        throw new Error("Request not needed");
      }

      setSelectedUser(await request.json());
    } catch {
      setSelectedUser(undefined);
    }
  }

  async function handlerRequest() {
    if (status === "loading") {
      return;
    }
    try {
      setError("");
      setStatus("loading");
      await requestDataset();
      setStatus("ready");
    } catch (e) {
      if (e.message === "Scooter not found") {
        setError("Скутер не найден");
      } else {
        setError(e.message);
      }
      setStatus("error");
    }
  }

  function kopecksToRubles(kopecks: number) {
    return (kopecks / 100).toFixed(2) + " ₽";
  }

  function modeToText(mode: string) {
    switch (mode) {
      case "0":
        return "Нормальный";
      case "1":
        return "Эко";
      case "2":
        return "Спорт";
      default:
        return mode;
    }
  }

  function processName(input: string) {
    return input;
  }

  function commandToText(command: string) {
    const translation = commandDefinitions.find((item) => item.command === command)?.ruTitle;
    if (translation)
      return translation;

    // return command;
    if (command.startsWith("set_speed_limit")) {
      const [, , , mode, speed] = command.split("_");
      const modeText = modeToText(mode);

      return `Установить лимит ${speed} км/ч в режиме ${modeText}`;
    }

    return command
  }

  function paymentStatusToText(status: string) {
    switch (status) {
      case "Paid":
        return "Оплачено";
      case "Authorized":
        return "Авторизовано";
      case "Canceled":
        return "Отменено";
      default:
        return status;
    }
  }

  const [lastCenter, setLastCenter] =
    React.useState<LeafLet.LatLngLiteral>(initialCenter);
  const [lastZoom, setLastZoom] = React.useState<number>(initialState.zoom);

  function handlerMapMove() {
    const center = map.getCenter();

    setLastCenter({
      lat: center.lat,
      lng: center.lng,
    });

    const zoom = map.getZoom();
    if (zoom !== lastZoom) {
      setLastZoom(zoom);
    }
  }

  React.useEffect(() => {
    if (!map) return;
    map.on("move", handlerMapMove);
    return () => {
      map.off("move", handlerMapMove);
    };
  }, [map]);

  React.useEffect(() => {
    setStatus("empty");
  }, [fromTime, toTime]);

  React.useEffect(() => {
    if (status !== "empty") {
      return;
    }
    try {
      if (fromTime.isAfter(toTime)) {
        throw new Error("Начальная дата должна быть раньше конечной");
      }
      if (toTime.diff(fromTime, "day") > 7) {
        throw new Error("Диапазон не должен превышать 7 дней");
      }

      handlerRequest();
    } catch (error) {
      setStatus("ready");
      setError(error.message);
      setDataset({
        rents: [],
        track: [],
        commands: [],
        deactivations: [],
      });
    }
  }, [status]);

  React.useEffect(() => {
    if (typeof selectedUser !== "string") {
      return;
    }
    userRequest();
  }, [selectedUser]);

  React.useEffect(() => {
    if (typeof selectedRent !== "string") {
      return;
    }
    const rent = dataset.rents.find((e) => e.rentID === selectedRent);
    setSelectedRent(rent);
  }, [dataset, selectedRent]);

  React.useEffect(() => {
    if (typeof selectedPayment !== "string") {
      return;
    }
    for (const rent of dataset.rents) {
      for (const payment of rent.payments ?? []) {
        if (payment.invoiceID === selectedPayment) {
          setSelectedPayment(payment);
          return;
        }
      }
    }
  }, [dataset, selectedPayment]);

  React.useEffect(() => {
    if (typeof selectedDeactivation !== "string") {
      return;
    }
    const deactivation = dataset.deactivations.find(
      (e) => e.id === selectedDeactivation
    );
    setSelectedDeactivation(deactivation);
  }, [dataset, selectedDeactivation]);

  function getIconByStatus(status: string) {
    switch (status) {
      /// Payment statuses
      case "PaymentCanceled":
        return <Icons.RemoveCircle color="disabled" />;
      case "PaymentAuthorized":
        return <Icons.CheckCircleOutline color="success" />;
      case "PaymentPaid":
        return <Icons.CheckCircle color="success" />;

      /// Deactivation statuses
      case "DeactivationStarted":
        return <Icons.Settings color="warning" />;
      case "DeactivationEnded":
        return <Icons.Settings color="warning" />;

      /// Command statuses
      case "Confirmed":
        return <Icons.Terminal color="success" />;
      //return <Icons.CheckCircleOutline color="success"  />;
      case "CommandNotSupported":
        return <Icons.PsychologyAlt color="error" />;
      case "TimeOut":
        return <Icons.Alarm color="error" />;
      case "ScooterNotConnected":
        return <Icons.PowerOff color="error" />;
      case "Error":
        return <Icons.ErrorOutline color="error" />;
      default:
        return <Icons.HelpOutline color="error" />;
    }
  }

  function nextPeriod() {
    const diff = toTime.diff(fromTime);
    const newFromTime = fromTime.add(diff);
    const newToTime = toTime.add(diff);

    setFromTime(newFromTime);
    setToTime(newToTime);
  }

  function prevPeriod() {
    const diff = toTime.diff(fromTime);
    const newFromTime = fromTime.subtract(diff);
    const newToTime = toTime.subtract(diff);

    setFromTime(newFromTime);
    setToTime(newToTime);
  }

  function saveMapState() {
    // const [link, position] = window.location.hash.split("state=");
    const [link, position] = window.location.href.split("state=");

    // window.removeEventListener("hashchange", loadFromHash);
    // window.location.replace(
    //   `${link}state=${[lat, lng, zoom, from, to, slider].join(",")}`
    // );

    // window.addEventListener("hashchange", loadFromHash);
    navigator.clipboard.writeText(
      `${link}/state=${[
        lastCenter.lat,
        lastCenter.lng,
        lastZoom,
        fromTime.toISOString(),
        toTime.toISOString(),
        sliderPosition,
        sliderMax,
      ].join(",")}`
    );
  }

  function storyComponent({
    key,
    time,
    sliderValue,
    status,
    text,
    isRented,
    rentStarted,
    rentFinished,
    isDeactivated,
    deactivationStarted,
    deactivationFinished,
    userId,
    userBadge,
    rentId,
    deactivationId,
    paymentId,
  }: {
    key: string;
    time: string;
    sliderValue: number;
    status: string;
    text: React.ReactNode;
    isRented: boolean;
    rentStarted: boolean;
    rentFinished: boolean;
    isDeactivated: boolean;
    deactivationStarted: boolean;
    deactivationFinished: boolean;
    userId?: string;
    userBadge?: string;
    rentId?: string;
    deactivationId?: string;
    paymentId?: string;
  }) {
    return (
      <UI.ListItem
        key={key}
        disablePadding
        onClick={() => setSliderPosition(sliderValue)}
      >
        <UI.ListItemButton sx={{ pl: "48px" }}>
          {isRented && (
            <UI.Box
              sx={{
                left: 8,
                bgcolor: "green",
                top: rentFinished ? "50%" : 0,
                borderTopLeftRadius: rentFinished ? "24px" : 0,
                borderTopRightRadius: rentFinished ? "24px" : 0,

                bottom: rentStarted ? "50%" : 0,
                borderBottomLeftRadius: rentStarted ? "24px" : 0,
                borderBottomRightRadius: rentStarted ? "24px" : 0,
                position: "absolute",
                width: 5,
              }}
            ></UI.Box>
          )}
          {isDeactivated && (
            <UI.Box
              sx={{
                left: 22,
                bgcolor: "orange",
                top: deactivationFinished ? "50%" : 0,
                borderTopLeftRadius: deactivationFinished ? "24px" : 0,
                borderTopRightRadius: deactivationFinished ? "24px" : 0,

                bottom: deactivationStarted ? "50%" : 0,
                borderBottomLeftRadius: deactivationStarted ? "24px" : 0,
                borderBottomRightRadius: deactivationStarted ? "24px" : 0,
                position: "absolute",
                width: 5,
              }}
            ></UI.Box>
          )}
          <UI.ListItemIcon
            onClick={() => {
              setInfoBox(`Результат выполнения команды: ${status}`);
            }}
          >
            {getIconByStatus(status)}
          </UI.ListItemIcon>
          <UI.ListItemText primary={text} secondary={time} />
          <UI.Stack direction="row" spacing={1}>
            {paymentId && (
              <UI.Chip
                label={<Icons.InfoOutlined />}
                color="info"
                variant="outlined"
                clickable
                onClick={() => setSelectedPayment(paymentId)}
              />
            )}
            {deactivationId && (
              <UI.Chip
                // label={<Icons.Settings />}
                label={getShortId(deactivationId)}
                icon={<Icons.Settings />}
                color="warning"
                variant="filled"
                clickable
                onClick={() => setSelectedDeactivation(deactivationId)}
              />
            )}
            {userId && (
              <UI.Chip
                label={userBadge ?? userId}
                icon={<Icons.Person />}
                color="primary"
                variant="filled"
                clickable
                onClick={() => setSelectedUser(userId)}
              />
            )}
            {rentId && (
              <UI.Chip
                label={getShortId(rentId)}
                icon={<Icons.AttachMoney />}
                color="success"
                variant="filled"
                clickable
                onClick={() => setSelectedRent(rentId)}
              />
            )}
          </UI.Stack>
        </UI.ListItemButton>
      </UI.ListItem>
    );
  }

  function getShortId(id?: string) {
    try {
      const idLength = id.length;
      return id?.substring(idLength - 4, idLength);
    } catch {
      return "****";
    }
  }


  type Event =
    {
      type: "command",
      item: CommandItem
    } |
    {
      type: "rent_start" | "rent_finish"
      item: RentItem
    } |
    {
      type: "payment"
      item: PaymentItem
    } |
    {
      type: "deactivation_start" | "deactivation_finish"
      item: DeactivationItem
    } |
    {
      type: "track"
      item: TrackItem
    }

  type TimedEvent = Event & {
    date: Dayjs
  }


  const events: TimedEvent[] = useMemo(() => {
    let result = [] as TimedEvent[];

    for (const item of dataset.commands) {
      result.push({
        type: "command",
        date: dayjs(item.whenUTC),
        item: item,
      });
    }

    for (const item of dataset.rents) {
      result.push({
        type: "rent_start",
        date: dayjs(item.createdAt),
        item: item,
      });
      if (item.terminatedAt) {
        result.push({
          type: "rent_finish",
          date: dayjs(item.terminatedAt),
          item: item,
        });
      }

      for (const payment of item.payments ?? []) {
        payment.rentID = item.rentID;
        const dateString =
          payment.completeAt ??
          payment.authorizedAt ??
          payment.issuedAt ??
          item.createdAt;
        result.push({
          type: "payment",
          date: dayjs(dateString),
          item: payment,
        });
      }
    }

    for (const item of dataset.deactivations) {
      result.push({
        type: "deactivation_start",
        date: dayjs(item.startTime),
        item: item,
      });
      if (item.endTime) {
        result.push({
          type: "deactivation_finish",
          date: dayjs(item.endTime),
          item: item,
        });
      }
    }

    for (const item of dataset.track) {
      result.push({
        type: "track",
        date: dayjs(item.time),
        item: item,
      });
    }

    result = result.sort((a, b) => (dayjs(a.date).isAfter(b.date) ? 1 : -1));

    return result;
  }, [dataset])


  const { markerDate, markerState, markerPosition }: MapMarker = useMemo(() => {
    const pos = initialState.nowSlider ? initialState.nowSlider : sliderPosition;

    const time = fromTime.add((pos + 1) * sliderStepMs);

    let ongoingRentIds: Set<string> = new Set<string>();
    let ongoingDeactivationIds: Set<string> = new Set<string>();
    let markerDate: Dayjs | undefined;
    let markerPosition: any = undefined;
    for (let i = 0; i < events.length; i++) {
      const event = events[i];
      if (event.date.isBefore(time)) {
        if (event.type === "rent_start") {
          if (event.item?.rentID)
            ongoingRentIds.add(event.item?.rentID);
        } else if (event.type === "rent_finish") {
          if (event.item?.rentID)
            ongoingRentIds.delete(event.item?.rentID);
          if (event.item?.continuationRentID)
            ongoingRentIds.add(event.item?.continuationRentID);
        } else if (event.type === "deactivation_start") {
          if (event.item?.id)
            ongoingDeactivationIds.add(event.item?.id)
        } else if (event.type === "deactivation_finish") {
          if (event.item?.id)
            ongoingDeactivationIds.delete(event.item?.id)
        } else if (event.type === "track") {
          markerDate = event.date;
          markerPosition = [event.item.lon, event.item.lat];
        }
      }
    }

    return {
      markerDate,
      markerState: {
        rentIds: Array.from(ongoingRentIds.values()),
        deactivateIds: Array.from(ongoingDeactivationIds.values()),
      },
      markerPosition
    }

  }, [initialState.nowSlider, sliderPosition, events])


  const { storyLog, tracks, sliderMarks }: Log = useMemo(() => {
    const tracks = [];
    const storyLog = [];

    const sliderMarks = [] as Array<SliderMark>;

    let ongoingRentCount = 0;
    let prevRentCount = 0;
    let ongoingDeactivationCount = 0;
    let prevDeactivationCount = 0;

    let currentTrack = [] as [number, number][]; // coloured polyline
    let index = 0;
    for (const item of events) {
      const time = item.date.format("D MMMM в HH:mm:ss");

      let text: React.ReactNode;
      let status: string;
      let userId: string | undefined;
      let userBadge: string | undefined;
      let rentId: string | undefined;
      let deactivationId: string | undefined;
      let paymentId: string | undefined;

      if (item.type === "rent_start") {
        ongoingRentCount += 1;

        const rent = item.item

        if (rent.continuationOfID) {
          text = "Переход на поминутный тариф";
        } else {
          text = "Начало аренды";
        }
        userId = rent?.createdBy;
        userBadge = rent?.badgeCreatedBy;

        status = "Confirmed";
        rentId = rent?.rentID;
      } else if (item.type === "rent_finish") {
        ongoingRentCount -= 1;

        const rent = item.item

        if (rent.continuationRentID) {
          text = "Окончание оплаченного времени";
        } else {
          text = "Завершение аренды";
          userId = rent?.terminatedBy;
          userBadge = rent?.badgeTerminatedBy;
        }
        status = "Confirmed";
        rentId = rent?.rentID;
      } else if (item.type === "deactivation_start") {
        ongoingDeactivationCount += 1

        const reason =
          deactivationTypes.find((e) => e.id === item.item?.typeId)?.ruName ??
          item.item?.typeId;
        text = (
          <>
            {"Деактивирован"}
            <UI.Typography
              variant="caption"
              color="textSecondary"
              display="block"
            >
              {reason}
            </UI.Typography>
          </>
        );
        deactivationId = item.item?.id;
        status = "DeactivationStarted";
        userId = item.item?.deactivatedBy;
        userBadge = item.item?.badgeDeactivatedBy;
      } else if (item.type === "deactivation_finish") {
        ongoingDeactivationCount -= 1

        text = "Отмена деактивации";
        deactivationId = item.item?.id;
        status = "DeactivationEnded";
        userId = item.item?.removedDeactivationBy;
        userBadge = item.item?.badgeRemovedDeactivationBy;
      } else if (item.type === "command") {
        text = commandToText(item.item.command);
        status = item.item.result;
        userId = item.item.userId;
        userBadge = item.item.badgeUser;
        rentId = item.item?.rentId;
      } else if (item.type === "payment") {
        const payment = item.item;

        const statusText = paymentStatusToText(payment.status);
        const rubles = payment.amountKopecks
          ? kopecksToRubles(payment.amountKopecks)
          : "";
        text = `${statusText} ${rubles}`;

        status = `Payment${payment.status}`;

        paymentId = payment.invoiceID;

        rentId = payment.rentID;
        // userId = item.item.userId;
        // userBadge = item.item.badge;
      } else if (item.type === "track") {
        currentTrack.push([item.item.lon, item.item.lat]);

        const diffMs = item.date.diff(fromTime);
        const diffSliderSteps = Math.floor(diffMs / sliderStepMs)
        sliderMarks.push({ value: diffSliderSteps });

        continue;
      } else {
        continue;
      }

      if ((ongoingRentCount > 0 && prevRentCount == 0) ||
        (ongoingRentCount == 0 && prevRentCount > 0)) {
        tracks.push({ track: currentTrack, inRent: prevRentCount > 0 });
        currentTrack = [];
      }

      const isRented = ongoingRentCount + prevRentCount > 0;
      const rentStarted = ongoingRentCount == 1 && prevRentCount == 0;
      const rentFinished = ongoingRentCount == 0 && prevRentCount == 1;

      const isDeactivated = ongoingDeactivationCount + prevDeactivationCount > 0;
      const deactivationStarted = ongoingDeactivationCount == 1 && prevDeactivationCount == 0;
      const deactivationFinished = ongoingDeactivationCount == 0 && prevDeactivationCount == 1;


      prevDeactivationCount = ongoingDeactivationCount;
      prevRentCount = ongoingRentCount;

      if (item.date.isAfter(toTime) || item.date.isBefore(fromTime)) {
        continue;
      }

      const sliderValue = Math.floor(item.date.diff(fromTime) / sliderStepMs);

      storyLog.push(
        storyComponent({
          key: index++ + item.date.toISOString(),
          time: time,
          sliderValue: sliderValue,
          status: status,
          text: text,
          isRented: isRented,
          rentStarted: rentStarted,
          rentFinished: rentFinished,
          isDeactivated: isDeactivated,
          deactivationStarted: deactivationStarted,
          deactivationFinished: deactivationFinished,
          userId: userId,
          userBadge: userBadge,
          rentId: rentId,
          deactivationId: deactivationId,
          paymentId: paymentId,
        })
      );
    }

    if (currentTrack.length > 0) {
      tracks.push({ track: currentTrack, inRent: ongoingRentCount > 0 });
    }

    storyLog.reverse();

    const sliderMaxMs = toTime.diff(fromTime);
    const sliderMaxSteps = Math.floor(sliderMaxMs / sliderStepMs)

    if (initialState.nowSlider) {
      setSliderMax(initialState.maxSlider);
      setSliderPosition(initialState.nowSlider);
    } else {
      setSliderMax(sliderMaxSteps);
      setSliderPosition(sliderMaxSteps);
    }

    // calcMarkerPosition(true);

    return {
      tracks,
      storyLog,
      sliderMarks
    }
  }, [events])

  const initialViewportForSharedLink: boolean = initialState.isSharedLinkView && lastCenter.lat == initialCenter.lat && lastCenter.lng == initialCenter.lng && lastZoom == initialState.zoom

  useEffect(() => {
    // hack: has the use moved the viewport after the shared linked view loaded. If not, do not follow the marker
    if (initialViewportForSharedLink)
      return
    if (map && markerPosition) {
      map.setView(markerPosition, 15);
    }
  }, [map, storyLog, initialState]) // intentional dependency on storyLog instead of markerPosition, intentional absence of dependency on initialViewportForSharedLink

  const genMarkerDiv = (colorStr: string, iconName: string) => {
    return `
    <div class="map__marker">
      <div class="map__marker_arrow"></div>
      <div class="map__marker_circle" style="background-color:${colorStr};"></div>
      <div class="map__marker_content" style="background-image:url(./icons/${iconName}.png);"></div>
      </div>
    `
  }

  const markerImage = (() => {
    // in order to determine whether the vehicle is scooter or car
    // we need to fetch the latest info about the vehicle
    // we do not have a call to fetch single scooter for now
    // thus we use inderect way to determine the vehicle type
    let vehicleType = "scooter";
    if (scooterId.length > 4) // long enough ID
      vehicleType = "car";

    if (markerState.rentIds.length > 0) {
      return genMarkerDiv("#219653", vehicleType);
    } else if (markerState.deactivateIds.length > 0) {
      const deactivationId = markerState.deactivateIds.length > 0 ? markerState.deactivateIds[0] : null;

      const byLowCharge =
        "LowCharge" ===
        dataset.deactivations.find((e) => e.id === deactivationId)?.typeId;
      if (byLowCharge) {
        return genMarkerDiv("#F2994A", "charging");;
      }
      return genMarkerDiv("#808080", "gear");
    }
    return genMarkerDiv("#00A2E8", vehicleType);
  })();
  const markerIcon = new LeafLet.DivIcon({
    html: markerImage,
    className: "svg-icon",
    iconSize: [32, 48],
    iconAnchor: [16, 48],
    popupAnchor: [0, 0],
  });

  const [dragging, setDragging] = React.useState(false);
  const [storyWidth, setStoryOffset] = React.useState<number>(450);
  const [startX, setStartX] = React.useState(undefined);

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartX(e.clientX ?? e.touches[0].clientX);
  };

  const handleMouseUp = () => {
    if (dragging) {
      setDragging(false);
      setStartX(undefined);
    }
  };

  const handleMouseMove = (e) => {
    if (e.cancelable) {
      e.preventDefault();
    }
    if (dragging) {
      const x = e.clientX ?? e.touches[0].clientX;
      let value = storyWidth + x - startX;
      value = Math.max(300, value);
      setStoryOffset(value);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);

    // document.addEventListener("touchend", handleMouseUp);
    // document.addEventListener("touchmove", handleMouseMove, { passive: false });

    document.body.style.userSelect = dragging ? "none" : "auto";
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);

      // document.removeEventListener("touchend", handleMouseUp);
      // document.removeEventListener("touchmove", handleMouseMove);
    };
  }, [dragging]);

  const isMobile = !UI.useMediaQuery("(min-width:850px)");

  const [bottomNavigationValue, setBottomNavigationValue] = React.useState(1);

  function hashChanged() {
    if (location.hash.includes("state=")) {
      location.reload();
    }
  }

  React.useEffect(() => {
    window.addEventListener("hashchange", hashChanged);

    return () => {
      window.removeEventListener("hashchange", hashChanged);
    };
  }, []);

  function formatPhone(phone: number) {
    return (phone < 10_000 ? "***" : "+") + phone;
  }

  function deactivationContent() {
    const e = selectedDeactivation;
    if (typeof e !== "object") {
      return <LoadingBox />;
    }
    return contentBox([
      {
        name: "Id",
        value: e.id,
        icon: <Icons.Fingerprint />,
        canCopy: true,
      },
      {
        name: "Тип",
        value:
          deactivationTypes.find((k) => k.id === e.typeId)?.ruName ?? e.typeId,
        icon: <Icons.Badge />,
      },
      {
        name: "Комментарий",
        value: e.comment,
        icon: <Icons.Comment />,
      },
      {
        name: "Id скутера",
        value: e.scooterId,
        icon: <Icons.ElectricScooter />,
        canCopy: true,
      },
      {
        name: "Начало деактивации",
        value: e.startTime,
        valueFormatter: (e) => dayjs(e).format("DD/MM/YYYY HH:mm:ss"),
        icon: <Icons.QueryBuilder />,
      },
      {
        name: "Деактивировал",
        value: e.deactivatedBy,
        valueFormatter: (_) => userIdToName(e.deactivatedBy),
        icon: <Icons.Person />,
        actions: [
          {
            name: "Профиль",
            icon: <Icons.Person />,
            onClick: () => {
              setSelectedUser(e.deactivatedBy);
            },
          },
        ],
      },
      {
        name: "Окончание деактивации",
        value: e.endTime,
        valueFormatter: (e) => dayjs(e).format("DD/MM/YYYY HH:mm:ss"),
        icon: <Icons.QueryBuilder />,
      },
      {
        hide: !e.endTime,
        name: "Вернул на линию",
        value: e.removedDeactivationBy,
        valueFormatter: (_) => userIdToName(e.removedDeactivationBy),
        icon: <Icons.Person />,
        actions: [
          {
            name: "Профиль",
            icon: <Icons.Person />,
            onClick: () => {
              setSelectedUser(e.removedDeactivationBy);
            },
          },
        ],
      },
    ]);
  }

  function paymentContent() {
    const e = selectedPayment;
    if (typeof e !== "object") {
      return <LoadingBox />;
    }
    return contentBox([
      {
        name: "Номер транзакции",
        value: e.transaction,
        icon: <Icons.Tag />,
        canCopy: true,
        actions: [
          {
            name: "Посмотреть в CloudPayments",
            icon: <Icons.OpenInNew />,
            onClick: () => {
              const href = `https://merchant.cloudpayments.ru/transactions/${e.transaction}`;
              if (href) {
                window.open(href, "_blank");
              }
            },
          },
        ],
      },
      {
        name: "Статус транзакции",
        value: e.status,
        valueFormatter: (e) => paymentStatusToText(e),
        icon: <Icons.Badge />,
      },
      {
        name: "Id инвойса",
        value: e.invoiceID,
        icon: <Icons.Tag />,
        canCopy: true,
      },
      {
        name: "Размер платежа",
        value: e.amountKopecks,
        valueFormatter: (e) => kopecksToRubles(e),
        icon: <Icons.CurrencyRuble />,
      },
      {
        name: "Дата платежа",
        value: e.issuedAt,
        valueFormatter: (e) => dayjs(e).format("DD/MM/YYYY HH:mm:ss"),
        icon: <Icons.QueryBuilder />,
      },
      {
        name: "Дата авторизации",
        value: e.authorizedAt,
        valueFormatter: (e) => dayjs(e).format("DD/MM/YYYY HH:mm:ss"),
        icon: <Icons.QueryBuilder />,
      },
      {
        name: "Дата завершения",
        value: e.completeAt,
        valueFormatter: (e) => dayjs(e).format("DD/MM/YYYY HH:mm:ss"),
        icon: <Icons.QueryBuilder />,
      },
      {
        name: "Залог",
        value: e.isDeposit ? "Да" : "Нет",
        icon: <Icons.CreditScore />,
      },

      {
        name: "Ссылка на чек",
        value: e.receiptLink,
        icon: <Icons.Receipt />,
        canCopy: true,
        actions: [
          {
            name: "Ссылка на чек",
            icon: <Icons.OpenInNew />,
            onClick: () => {
              const href = e.receiptLink;
              if (href) {
                window.open(href, "_blank");
              }
            },
          },
        ],
      },
      {
        name: "Ссылка на чек возврата",
        value: e.returnReceiptLink,
        icon: <Icons.Receipt />,
        canCopy: true,
        actions: [
          {
            name: "Ссылка на чек возврата",
            icon: <Icons.OpenInNew />,
            onClick: () => {
              const href = e.returnReceiptLink;
              if (href) {
                window.open(href, "_blank");
              }
            },
          },
        ],
      },
    ]);
  }

  type ContentBoxItem = {
    hide?: boolean;
    name: string;
    value: any;
    valueFormatter?: (value: any) => string;
    icon: React.ReactNode;
    canCopy?: boolean;
    canPhone?: boolean;
    actions?: Array<{
      name: string;
      onClick: () => void;
      icon: React.ReactNode;
    }>;
  };

  function contentBox(row: Array<ContentBoxItem>) {
    return (
      <UI.List sx={{ px: 2 }}>
        {row
          .filter((e) => e.value !== undefined && !e.hide)
          .map((e) => {
            const value = e.valueFormatter
              ? e.valueFormatter(e.value)
              : e.value;
            return (
              <UI.ListItem
                secondaryAction={
                  <UI.Stack direction="row" spacing={2}>
                    {e.canCopy && (
                      <UI.IconButton
                        edge="end"
                        onClick={() => navigator.clipboard.writeText(value)}
                      >
                        <Icons.ContentCopy />
                      </UI.IconButton>
                    )}
                    {e.canPhone && (
                      <UI.IconButton
                        edge="end"
                        onClick={() => {
                          const phone = value.replace(/\D/g, "");
                          if (phone.length === 11) {
                            window.open(`tel:${phone}`);
                          }
                        }}
                      >
                        <Icons.Phone />
                      </UI.IconButton>
                    )}
                    {e.actions?.map((action) => (
                      <UI.IconButton edge="end" onClick={action.onClick}>
                        {action.icon}
                      </UI.IconButton>
                    ))}
                  </UI.Stack>
                }
              >
                <UI.ListItemIcon>{e.icon}</UI.ListItemIcon>
                <UI.ListItemText primary={e.name} secondary={value} />
              </UI.ListItem>
            );
          })}
      </UI.List>
    );
  }

  function fareToText(fare: string) {
    switch (fare) {
      case "perMinute":
        return "Поминутный";
      case "fixedTime":
        return "На фиксированное время";
      default:
        return fare;
    }
  }
  function rentStatusToText(status: string) {
    switch (status) {
      case "Starting":
        return "Начало";
      case "Active":
        return "Активный";
      case "Ending":
        return "Завершение";
      case "LockedOutOfZone":
        return "Заблокирован за пределами зоны";
      case "Terminated":
        return "Завершен";
      default:
        return status;
    }
  }
  function distanceToText(distance: number) {
    if (distance < 1000) return `${distance} м`;
    return `${(distance / 1000).toFixed(2)} км`;
  }
  function terminationReasonToText(reason: string) {
    switch (reason) {
      case "ExplicitEndByUser":
        return "Завершено пользователем";
      case "TimeOut":
        return "Вышло время";
      case "CantUnlockScooter":
        return "Не удалось разблокировать скутер";
      case "CantAcquirePayment":
        return "Не удалось получить оплату";
      case "CantSecureInsurance":
        return "Не удалось застраховать";
      case "InsufficientFunds":
        return "Недостаточно средств";
      default:
        return reason;
    }
  }

  function rentContent() {
    const e = selectedRent;
    if (typeof e !== "object") {
      return <LoadingBox />;
    }
    return contentBox([
      {
        name: "Id аренды",
        value: e.rentID,
        icon: <Icons.Fingerprint />,
        canCopy: true,
      },
      {
        name: "Предыдущая аренда",
        value: e.continuationOfID,
        icon: <Icons.Undo />,
        actions: [
          {
            name: "Открыть",
            icon: <Icons.OpenInNew />,
            onClick: () => {
              setSelectedRent(e.continuationOfID);
            },
          },
        ],
      },
      {
        name: "Следующая аренда",
        value: e.continuationRentID,
        icon: <Icons.Redo />,
        actions: [
          {
            name: "Открыть",
            icon: <Icons.OpenInNew />,
            onClick: () => {
              setSelectedRent(e.continuationRentID);
            },
          },
        ],
      },
      {
        name: "Тариф",
        value: e.fare,
        valueFormatter: (e) => fareToText(e),
        icon: <Icons.CorporateFare />,
      },
      {
        name: "Статус",
        value: e.status,
        valueFormatter: (e) => rentStatusToText(e),
        icon: <Icons.QuestionMark />,
      },
      {
        name: "Время начала аренды",
        value: e.startTime,
        valueFormatter: (e) => dayjs(e).format("DD/MM/YYYY HH:mm:ss"),
        icon: <Icons.QueryBuilder />,
      },
      {
        name: "Время окончания аренды",
        value: e.rideEndTime,
        valueFormatter: (e) => dayjs(e).format("DD/MM/YYYY HH:mm:ss"),
        icon: <Icons.QueryBuilder />,
      },
      {
        name: "Пройденное расстояние",
        value: e.totalDistance,
        valueFormatter: (e) => distanceToText(e),
        icon: <Icons.SocialDistance />,
      },
      {
        name: "Стоимость аренды",
        value: e.paid,
        valueFormatter: (e) => kopecksToRubles(e),
        icon: <Icons.CurrencyRuble />,
      },
      {
        name: "Аренда создана",
        value: e.createdAt,
        valueFormatter: (e) => dayjs(e).format("DD/MM/YYYY HH:mm:ss"),
        icon: <Icons.QueryBuilder />,
      },
      {
        name: "Инициатор начала аренды",
        value: e.createdBy,
        valueFormatter: (_) => userIdToName(e.createdBy),
        icon: <Icons.Person />,
        actions: [
          {
            name: "Профиль",
            icon: <Icons.Person />,
            onClick: () => {
              setSelectedUser(e.createdBy);
            },
          },
        ],
      },
      {
        name: "Время завершения аренды",
        value: e.terminatedAt,
        valueFormatter: (e) => dayjs(e).format("DD/MM/YYYY HH:mm:ss"),
        icon: <Icons.QueryBuilder />,
      },
      {
        name: "Причина завершения",
        value: e.terminationReason,
        valueFormatter: (e) => terminationReasonToText(e),
        icon: <Icons.RemoveCircleOutline />,
      },

      {
        name: "Инициатор завершения",
        value: e.terminatedBy,
        valueFormatter: (_) => userIdToName(e.terminatedBy),
        icon: <Icons.Person />,
        actions: [
          {
            name: "Профиль",
            icon: <Icons.Person />,
            onClick: () => {
              setSelectedUser(e.terminatedBy);
            },
          },
        ],
      },

      {
        name: "Сообщение об ошибке",
        value: e.errorMessage,
        icon: <Icons.Message />,
      },
      {
        name: "Id скутера",
        value: e.scooterID,
        icon: <Icons.Fingerprint />,
        canCopy: true,
      },

      {
        name: "Город",
        value: e.city,
        icon: <Icons.LocationCity />,
      },

      {
        name: "Id страхового полиса",
        value: e.insurancePolicyID,
        icon: <Icons.Tag />,
        canCopy: true,
        actions: [
          {
            name: "Полис",
            icon: <Icons.OpenInNew />,
            onClick: () => {
              const href = e.insurancePolicyLink;
              if (href) {
                window.open(href, "_blank");
              }
            },
          },
        ],
      },

      {
        name: "Клиент пользователя",
        value: e.userAgent,
        icon: <Icons.PhoneAndroid />,
      },
    ]);
  }

  function profileContent() {
    const e = selectedUser;
    if (typeof e !== "object") {
      return <LoadingBox />;
    }
    return contentBox([
      {
        name: "Id",
        value: e.id,
        icon: <Icons.Fingerprint />,
        canCopy: true,
      },
      {
        name: "ФИО",
        value: e.name ?? "***",
        icon: <Icons.Badge />,
      },
      {
        name: "Email",
        value: e.email ?? "***",
        icon: <Icons.AlternateEmail />,
        canCopy: true,
      },
      {
        name: "Завершений на парковке",
        value: e.successfulRentsCount,
        icon: <Icons.CheckCircle />,
      },
      {
        name: "Завершений вне парковки",
        value: e.insufficientFundsRentsCount,
        icon: <Icons.DoDisturbOn />,
      },
      {
        name: "Дата регистрации",
        value: e.registeredAt,
        valueFormatter: (e) => dayjs(e).format("DD/MM/YYYY HH:mm:ss"),
        icon: <Icons.QueryBuilder />,
      },
      {
        name: "Дата удаления",
        value: e.deletedAt,
        valueFormatter: (e) => dayjs(e).format("DD/MM/YYYY HH:mm:ss"),
        icon: <Icons.QueryBuilder />,
      },
      {
        name: "Номер телефона",
        value: e.phoneNum,
        valueFormatter: (e) => formatPhone(e),
        icon: <Icons.Phone />,
        canCopy: true,
        canPhone: true,
      },
    ]);
  }

  function mobile() {
    return (
      <>
        <UI.Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={status == "loading"}
        >
          <UI.Paper sx={{ px: 5, py: 2 }}>
            <UI.Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <UI.Typography variant="h5" component="div">
                Составляем отчёт
              </UI.Typography>
              <UI.CircularProgress color="inherit" sx={{ m: 2 }} />
            </UI.Box>
          </UI.Paper>
        </UI.Backdrop>

        <UI.Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!!error}
        >
          <UI.Paper sx={{ px: 5, py: 2 }}>
            <UI.Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <UI.Alert severity="error">
                <UI.AlertTitle>
                  {"При построении последнего отчёта возникла ошибка"}
                </UI.AlertTitle>
                {error}
              </UI.Alert>

              <UI.Button
                fullWidth={true}
                variant="contained"
                color="error"
                onClick={() => setError("")}
                sx={{ mt: 2 }}
              >
                OK
              </UI.Button>
            </UI.Box>
          </UI.Paper>
        </UI.Backdrop>

        <UI.Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!!infoBoxContent}
        >
          <UI.Paper sx={{ px: 5, py: 2 }}>
            <UI.Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <UI.Typography variant="h5" component="div">
                {infoBoxContent}
              </UI.Typography>

              <UI.Button
                fullWidth={true}
                variant="contained"
                color="primary"
                onClick={() => setInfoBox("")}
                sx={{ mt: 2 }}
              >
                OK
              </UI.Button>
            </UI.Box>
          </UI.Paper>
        </UI.Backdrop>

        {/* Selected user */}
        <UI.Dialog open={!!selectedUser}>
          <UI.DialogTitle>Пользователь</UI.DialogTitle>
          <UI.DialogContent>{profileContent()}</UI.DialogContent>
          <UI.DialogActions>
            <UI.Button
              onClick={() => setSelectedUser(undefined)}
              variant="contained"
              color="primary"
            >
              OK
            </UI.Button>
          </UI.DialogActions>
        </UI.Dialog>

        {/* Selected deactivation */}
        <UI.Dialog open={!!selectedDeactivation}>
          <UI.DialogTitle>Деактивация</UI.DialogTitle>
          <UI.DialogContent>{deactivationContent()}</UI.DialogContent>
          <UI.DialogActions>
            <UI.Button
              onClick={() => setSelectedDeactivation(undefined)}
              variant="contained"
              color="primary"
            >
              OK
            </UI.Button>
          </UI.DialogActions>
        </UI.Dialog>

        {/* Selected rent */}
        <UI.Dialog open={!!selectedRent}>
          <UI.DialogTitle>Аренда</UI.DialogTitle>
          <UI.DialogContent>{rentContent()}</UI.DialogContent>
          <UI.DialogActions>
            <UI.Button
              onClick={() => setSelectedRent(undefined)}
              variant="contained"
              color="primary"
            >
              OK
            </UI.Button>
          </UI.DialogActions>
        </UI.Dialog>

        {/* Selected payment */}
        <UI.Dialog open={!!selectedPayment}>
          <UI.DialogTitle>Транзакция</UI.DialogTitle>
          <UI.DialogContent>{paymentContent()}</UI.DialogContent>
          <UI.DialogActions>
            <UI.Button
              onClick={() => setSelectedPayment(undefined)}
              variant="contained"
              color="primary"
            >
              OK
            </UI.Button>
          </UI.DialogActions>
        </UI.Dialog>

        <UI.Box
          sx={{
            position: "fixed",
            top: "5.7vh",
            left: 0,
            right: 0,
            bottom: "7vh",
          }}
        >
          <UI.Box
            sx={{
              visibility: bottomNavigationValue == 0 ? "visible" : "hidden",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <UI.Paper sx={{ p: 2, mx: 2 }}>
              <UI.Typography variant="h5" component="div" sx={{ mb: 2 }}>
                Журнал
              </UI.Typography>

              <UI.Stack spacing={1}>
                <UI.TextField
                  sx={{ mb: 2 }}
                  label="Номер"
                  fullWidth={true}
                  value={currentScooterId}
                  onChange={(e) => {
                    setCurrentScooterId(e.target.value);
                  }}
                />
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="ru"
                >
                  <DateTimePicker
                    label="От"
                    value={fromTime}
                    closeOnSelect={true}
                    onAccept={(date) => date && setFromTime(date)}
                  />
                </LocalizationProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="ru"
                >
                  <DateTimePicker
                    label="До"
                    value={toTime}
                    closeOnSelect={true}
                    onAccept={(date) => date && setToTime(date)}
                  />
                </LocalizationProvider>
                <UI.Stack direction={"row"} spacing={2}>
                  <UI.Button
                    onClick={() => prevPeriod()}
                    variant="contained"
                    color="primary"
                  >
                    Предыдущий период
                  </UI.Button>
                  <UI.Button
                    onClick={() => nextPeriod()}
                    variant="contained"
                    color="primary"
                  >
                    Следующий период
                  </UI.Button>
                </UI.Stack>
                {status === "error" && (
                  <UI.Button
                    variant="contained"
                    color="warning"
                    startIcon={<Icons.Refresh />}
                    fullWidth={true}
                    onClick={() => setStatus("empty")}
                  >
                    Повторить
                  </UI.Button>
                )}
                <UI.Button
                  onClick={() => saveMapState()}
                  startIcon={<Icons.Share />}
                  variant="contained"
                  color="primary"
                >
                  Скопировать ссылку
                </UI.Button>
              </UI.Stack>
            </UI.Paper>
          </UI.Box>

          <UI.Box
            sx={{
              visibility: bottomNavigationValue == 1 ? "visible" : "hidden",
              overflow: "auto",
              bgcolor: "#fff",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <NoDataAlert show={storyLog.length === 0} />
            <UI.List>{storyLog}</UI.List>
          </UI.Box>

          <UI.Box
            sx={{
              visibility: bottomNavigationValue == 2 ? "visible" : "hidden",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <NoDataAlert show={tracks.length === 0} />

            <GeoMap.MapContainer
              center={initialCenter}
              zoom={initialState.zoom}
              scrollWheelZoom={true}
              zoomControl={false}
              ref={setMap}
            >
              <GeoMap.TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {tracks.map((item, index) => {
                return (
                  <GeoMap.Polyline
                    key={index}
                    pathOptions={item.inRent ? unlockLineView : lockLineView}
                    positions={item.track}
                  />
                );
              })}

              {markerPosition && (
                <GeoMap.Marker
                  position={markerPosition}
                  icon={markerIcon}
                ></GeoMap.Marker>
              )}

              <Legacy.default></Legacy.default>
            </GeoMap.MapContainer>
            <UI.Box
              sx={{
                zIndex: 890,
                position: "absolute",
                top: 12,
                right: 16,
                bgcolor: "rgba(255,255,255,1)",
                borderRadius: 6,
                p: 0.77,
              }}
            >
              <UI.Stack direction={"row"} spacing={2}>
                {markerState.deactivateIds.map(deactivationId =>
                (<UI.Chip
                  label={getShortId(deactivationId)}
                  icon={<Icons.Settings />}
                  color="warning"
                  variant="filled"
                  clickable
                  onClick={() =>
                    setSelectedDeactivation(deactivationId)
                  }
                />)
                )}
                {markerState.rentIds.map(rentId => (
                  <UI.Chip
                    label={getShortId(rentId)}
                    icon={<Icons.AttachMoney />}
                    color="success"
                    variant="filled"
                    clickable
                    onClick={() => setSelectedRent(rentId)}
                  />)
                )}
                {markerDate && (
                  <UI.Chip
                    label={markerDate?.format("DD.MM.YYYY HH:mm:ss")}
                    icon={<Icons.AccessTimeOutlined />}
                    color="info"
                    variant="filled"
                  />
                )}
              </UI.Stack>
            </UI.Box>
            <UI.Box
              sx={{
                zIndex: 880,
                position: "absolute",
                bottom: 24,
                left: 16,
                right: 16,
                px: 4,
                py: 0.5,
                bgcolor: "rgba(255,255,255,1)",
                borderRadius: 12,
              }}
            >
              <UI.Slider
                style={{ height: 8 }}
                value={sliderPosition}
                max={sliderMax}
                marks={sliderMarks}
                onChange={(_, value) =>
                  value && setSliderPosition(value as number)
                }
              />
            </UI.Box>
          </UI.Box>
        </UI.Box>

        <UI.Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
          }}
        >
          <NavigationMenu></NavigationMenu>
        </UI.Box>

        <UI.Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <UI.BottomNavigation
            showLabels
            value={bottomNavigationValue}
            onChange={(event, newValue) => {
              setBottomNavigationValue(newValue);
            }}
          >
            <UI.BottomNavigationAction
              label="Диапазон"
              icon={<Icons.CalendarMonth />}
            />
            <UI.BottomNavigationAction label="События" icon={<Icons.List />} />
            <UI.BottomNavigationAction label="Карта" icon={<Icons.Map />} />
          </UI.BottomNavigation>
        </UI.Paper>
      </>
    );
  }

  function desktop() {
    return (
      <>
        <UI.Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={status == "loading"}
        >
          <UI.Paper sx={{ px: 5, py: 2 }}>
            <UI.Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <UI.Typography variant="h5" component="div">
                Составляем отчёт
              </UI.Typography>
              <UI.CircularProgress color="inherit" sx={{ m: 2 }} />
            </UI.Box>
          </UI.Paper>
        </UI.Backdrop>

        <UI.Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!!error}
        >
          <UI.Paper sx={{ px: 5, py: 2 }}>
            <UI.Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <UI.Alert severity="error">
                <UI.AlertTitle>
                  {"При построении последнего отчёта возникла ошибка"}
                </UI.AlertTitle>
                {error}
              </UI.Alert>

              <UI.Button
                fullWidth={true}
                variant="contained"
                color="error"
                onClick={() => setError("")}
                sx={{ mt: 2 }}
              >
                OK
              </UI.Button>
            </UI.Box>
          </UI.Paper>
        </UI.Backdrop>

        <UI.Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!!infoBoxContent}
        >
          <UI.Paper sx={{ px: 5, py: 2 }}>
            <UI.Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <UI.Typography variant="h5" component="div">
                {infoBoxContent}
              </UI.Typography>
              <UI.Button
                fullWidth={true}
                variant="contained"
                color="primary"
                onClick={() => setInfoBox("")}
                sx={{ mt: 2 }}
              >
                OK
              </UI.Button>
            </UI.Box>
          </UI.Paper>
        </UI.Backdrop>

        {/* Selected user */}
        <UI.Dialog open={!!selectedUser}>
          <UI.DialogTitle>Пользователь</UI.DialogTitle>
          <UI.DialogContent>{profileContent()}</UI.DialogContent>
          <UI.DialogActions>
            <UI.Button
              onClick={() => setSelectedUser(undefined)}
              variant="contained"
              color="primary"
            >
              OK
            </UI.Button>
          </UI.DialogActions>
        </UI.Dialog>

        {/* Selected deactivation */}
        <UI.Dialog open={!!selectedDeactivation}>
          <UI.DialogTitle>Деактивация</UI.DialogTitle>
          <UI.DialogContent>{deactivationContent()}</UI.DialogContent>
          <UI.DialogActions>
            <UI.Button
              onClick={() => setSelectedDeactivation(undefined)}
              variant="contained"
              color="primary"
            >
              OK
            </UI.Button>
          </UI.DialogActions>
        </UI.Dialog>

        {/* Selected rent */}
        <UI.Dialog open={!!selectedRent}>
          <UI.DialogTitle>Аренда</UI.DialogTitle>
          <UI.DialogContent>{rentContent()}</UI.DialogContent>
          <UI.DialogActions>
            <UI.Button
              onClick={() => setSelectedRent(undefined)}
              variant="contained"
              color="primary"
            >
              OK
            </UI.Button>
          </UI.DialogActions>
        </UI.Dialog>

        {/* Selected payment */}
        <UI.Dialog open={!!selectedPayment}>
          <UI.DialogTitle>Транзакция</UI.DialogTitle>
          <UI.DialogContent>{paymentContent()}</UI.DialogContent>
          <UI.DialogActions>
            <UI.Button
              onClick={() => setSelectedPayment(undefined)}
              variant="contained"
              color="primary"
            >
              OK
            </UI.Button>
          </UI.DialogActions>
        </UI.Dialog>

        <UI.Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            maxHeight: "100vh",
            minHeight: "100vh",
            justifyContent: "space-between",
            alignItems: "stretch",
          }}
        >
          <NavigationMenu />

          <UI.Box
            sx={{
              p: 2,
              bgcolor: "white",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <UI.Box>
              <UI.Typography variant="h5" component="div">
                <UI.TextField
                  variant="outlined"
                  label="Журнал"
                  value={currentScooterId}
                  onChange={(e) => {
                    setCurrentScooterId(e.target.value);
                  }}
                />
              </UI.Typography>
            </UI.Box>
            <UI.Box>
              <UI.Stack direction="row" spacing={1}>
                <UI.IconButton onClick={() => saveMapState()}>
                  <Icons.Share />
                </UI.IconButton>
                <UI.IconButton onClick={() => prevPeriod()}>
                  <Icons.KeyboardArrowLeft />
                </UI.IconButton>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="ru"
                >
                  <DateTimePicker
                    label="От"
                    value={fromTime}
                    closeOnSelect={true}
                    onAccept={(date) => date && setFromTime(date)}
                  />
                </LocalizationProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="ru"
                >
                  <DateTimePicker
                    label="До"
                    value={toTime}
                    closeOnSelect={true}
                    onAccept={(date) => date && setToTime(date)}
                  />
                </LocalizationProvider>
                <UI.IconButton onClick={() => nextPeriod()}>
                  <Icons.KeyboardArrowRight />
                </UI.IconButton>
                {status === "error" && (
                  <UI.Button
                    variant="contained"
                    color="warning"
                    onClick={() => setStatus("empty")}
                  >
                    Повтор
                  </UI.Button>
                )}
              </UI.Stack>
            </UI.Box>
          </UI.Box>

          <UI.Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              height: "88vh",
              maxHeight: "88vh",
            }}
          >
            <UI.Box
              sx={{
                position: "relative",
                width: storyWidth,
                height: "100%",
                overflow: "auto",
                bgcolor: "#fff",
              }}
            >
              <NoDataAlert show={storyLog.length === 0} />
              <UI.List>{storyLog}</UI.List>
            </UI.Box>

            <UI.Box
              sx={{
                width: 30,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                cursor: "grab",
                bgcolor: "#eee",
              }}
              id="story-resize"
              // onTouchStart={handleMouseDown}
              onMouseDown={handleMouseDown}
            >
              <Icons.DragIndicator color="action" />
            </UI.Box>

            <UI.Box
              sx={{
                position: "relative",
                userSelect: "none",
                width: "calc(100% - 30px - " + storyWidth + "px)",
                height: "100%",
                overflow: "auto",
              }}
            >
              <NoDataAlert show={tracks.length === 0} />
              <GeoMap.MapContainer
                center={initialCenter}
                zoom={initialState.zoom}
                scrollWheelZoom={true}
                zoomControl={false}
                ref={setMap}
              >
                <GeoMap.TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {tracks.map((item, index) => {
                  return (
                    <GeoMap.Polyline
                      key={index}
                      pathOptions={item.inRent ? unlockLineView : lockLineView}
                      positions={item.track}
                    />
                  );
                })}

                {markerPosition && (
                  <GeoMap.Marker
                    position={markerPosition}
                    icon={markerIcon}
                  ></GeoMap.Marker>
                )}


                <Legacy.default></Legacy.default>

              </GeoMap.MapContainer>
              <UI.Box
                sx={{
                  zIndex: 890,
                  position: "absolute",
                  top: 12,
                  right: 55,
                  bgcolor: "rgba(255,255,255,1)",
                  borderRadius: 6,
                  p: 0.77,
                }}
              >
                <UI.Stack direction={"row"} spacing={2}>
                  {markerState.deactivateIds.map(deactivationId => (
                    <UI.Chip
                      label={getShortId(deactivationId)}
                      icon={<Icons.Settings />}
                      color="warning"
                      variant="filled"
                      clickable
                      onClick={() =>
                        setSelectedDeactivation(deactivationId)
                      }
                    />)
                  )}
                  {markerState.rentIds.map(rentId => (
                    <UI.Chip
                      label={getShortId(rentId)}
                      icon={<Icons.AttachMoney />}
                      color="success"
                      variant="filled"
                      clickable
                      onClick={() => setSelectedRent(rentId)}
                    />)
                  )}
                  {markerDate && (
                    <UI.Chip
                      label={markerDate?.format("DD.MM.YYYY HH:mm:ss")}
                      icon={<Icons.AccessTimeOutlined />}
                      color="info"
                      variant="filled"
                    />
                  )}
                </UI.Stack>
              </UI.Box>

              <UI.Box
                sx={{
                  zIndex: 880,
                  position: "absolute",
                  bottom: 8,
                  left: 55,
                  right: 55,
                  px: 4,
                  py: 0.5,
                  bgcolor: "rgba(255,255,255,1)",
                  borderRadius: 12,
                }}
              >
                <UI.Slider
                  style={{ height: 8 }}
                  value={sliderPosition}
                  max={sliderMax}
                  marks={sliderMarks}
                  onChange={(_, value) =>
                    value && setSliderPosition(value as number)
                  }
                />
              </UI.Box>
            </UI.Box>
          </UI.Box>
        </UI.Box>
      </>
    );
  }

  return isMobile ? mobile() : desktop();
});

const NoDataAlert = ({ show }: { show: boolean }) => {
  return (
    <UI.Box
      sx={{
        justifyContent: "center",
        alignItems: "center",

        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
        userSelect: "none",
        bgcolor: "rgba(0,0,0,0.5)",
        display: show ? "flex" : "none",
      }}
    >
      <UI.Typography
        variant="h3"
        component="div"
        sx={{
          color: "#fff",
        }}
      >
        Данных нет
      </UI.Typography>
    </UI.Box>
  );
};

const LoadingBox = () => (
  <UI.Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <UI.CircularProgress />
  </UI.Box>
);
