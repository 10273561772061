import React from 'react';
import './ButtonCommand.css';

interface ButtonProps {
  buttonText: string;
  additionalCssClass?: string;
  handleButtonClick: () => void;
}

const ButtonCommand: React.FC<ButtonProps> = (props) => {
  const {additionalCssClass, buttonText, handleButtonClick} = props;
  
  // ButtonCommand_back
  // ButtonCommand_rent
  // ButtonCommand

  const buttonClasses :string[] = ["ButtonCommand"];
  
  if(additionalCssClass)
    buttonClasses.push(additionalCssClass);

  const classesString = buttonClasses.join(" ");

  return (
    <button className={classesString} onClick={handleButtonClick}>
      {buttonText}
    </button>
  );
};

export default ButtonCommand;