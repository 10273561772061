import React, { FC, ReactNode } from 'react';
import './Popup.css';
import { Link } from 'react-router-dom';
import {scooterStoreInstance} from '../../ScootersStore';
import { observer } from 'mobx-react-lite';

type PopupProps = {
    status: string;
    id: string;
    battery: number;
    onClick?: () => void;
    children?: ReactNode;
};

const PopupComp = observer(({ children, status, id, battery, onClick, }:PopupProps) => {
    return (
        <div className='Popup__container'>
            <div>
                <div><b>Номер</b></div>
                <div> {id} </div>
            </div>
            <div>
                <div><b>Заряд</b></div>
                <div> {battery}% </div>
            </div>
            <div>
                <div><b>Статус</b></div>
                <button className='Popup__button'> {
                scooterStoreInstance.filterSelectOptions.find(option => option.value === status)?.text ?? "Активный"
                } </button>
            </div>
            <div className='Popup__arrow'>
                <Link target='_BLANK' to={`/scooters/${id}`}><div className='arrow'></div></Link>
            </div>
        </div>
    );
});

export default PopupComp;