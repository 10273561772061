import NavigationMenu from 'components/NavigationMenu/NavigationMenu';
import ScooterCommand from 'components/ScooterCommand/ScooterCommand';
import React from 'react';
import { useParams } from 'react-router-dom';
import './ScooterPage.css';
import ScooterDeactivation from 'components/ScooterDeactivation/ScooterDeactivation';
import StatusScooter from 'components/StatusScooter/StatusScooter';
import ScooterInfo from 'components/ScooterInfo/ScooterInfo';
import { useFreshScooterData } from 'hooks/useFreshScooterData';


const ScooterPage: React.FC = () => {
  const { scooterId } = useParams();

  useFreshScooterData()

  return (
    <div>
      <NavigationMenu></NavigationMenu>
      <div className='ScooterPage__container ScooterPage__flex'>
        <div>
        <h3 className='ScooterPage__title_scooter'>Карточка {scooterId}</h3>
        <ScooterInfo></ScooterInfo>
        </div>
        <div>
        <ScooterDeactivation></ScooterDeactivation>
        <StatusScooter isPopup={false} status={null}></StatusScooter>
        </div>
      </div>
      <ScooterCommand></ScooterCommand>
    </div>

  );
}

export default ScooterPage;