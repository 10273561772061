import { accessRightsStoreInstance } from "AccessRightsStore";
import { kicksharingAPITokenStoreInstance } from "KicksharingAPITokenStore";
import { apiUrl } from "config";
import { autorun, makeAutoObservable } from "mobx";

type CityJSON = {
    enName:string,
    ruName:string,
    centre: {
        coordinates:[number,number],
        type:"Point"
    }
}

export class CitiesStore {
    public Cities:CityJSON[] = [];

    public RussianNames : {
        [enName: string]: string ;
      } = {}

    public constructor()  {
       makeAutoObservable(this);
    }
}

export const citiesStoreInstance = new CitiesStore();

autorun(async () => {
    if (kicksharingAPITokenStoreInstance.token &&
        kicksharingAPITokenStoreInstance.confirmedToken &&
        accessRightsStoreInstance?.AccessRights?.operatorUIAccess) {
        console.log("Fetching known city descriptions...")
        try{
        const citiesFetchResult = await fetch(`${apiUrl}/cities`, {
          headers: {
            Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
          },
        });
        if(citiesFetchResult.ok) {
            const cityDescriptions: CityJSON[] = await citiesFetchResult.json();
            var russianTranslations:{
                [enName: string]: string ;
              } = {}
            cityDescriptions.forEach(entry => {
                russianTranslations[entry.enName] = entry.ruName;
            });
            
            citiesStoreInstance.Cities = cityDescriptions;
            citiesStoreInstance.RussianNames = russianTranslations;

            console.log(`Successfully obtained ${Object.keys(russianTranslations).length} city descriptions`);
        }

        }
        catch(e) {
            console.error("Error during cities description fetch", e)
        }
    }
})