import React from 'react';
import './MapLink.css';

interface MapLinkProps {
  lat: number;
  lng: number;
}

const MapLink: React.FC<MapLinkProps> = ({ lat, lng }) => {
  const mapUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

  return (
    <a href={mapUrl} target="_blank" rel="noopener noreferrer" className='MapLink_color'>
      Показать на карте
    </a>
  );
};

export default MapLink;