import { makeAutoObservable } from "mobx";

class KicksharingAPITokenStore {
  token: string | null = null;
  confirmedToken: string = '';

  constructor() {
    makeAutoObservable(this);
    this.token = localStorage.getItem("token");
    this.confirmedToken = localStorage.getItem('confirmedToken')
  }
  setToken(token: string) {
    this.token = token;
    localStorage.setItem("token", token);
  }

  removeToken() {
    this.token = null;
    localStorage.removeItem("token");
  }
  confirmToken() {
    this.confirmedToken = 'true';
    localStorage.setItem("confirmedToken", 'true');
  }
}

export const kicksharingAPITokenStoreInstance = new KicksharingAPITokenStore();