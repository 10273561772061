import { apiUrl } from 'config';
import { LatLngExpression } from 'leaflet';
import { makeAutoObservable, runInAction } from 'mobx';
import {kicksharingAPITokenStoreInstance} from 'KicksharingAPITokenStore';

interface Geometry {
  coordinates: LatLngExpression[][][];
  type: string;
}

interface Zone {
  name: string;
  city: string;
  kind: string;
  isEnabled: boolean;
  geometry: Geometry;
}

class ZonesStore {
  zones: Zone[] = [];

  constructor() {
    makeAutoObservable(this);
    this.fetchZones();
  }

  async fetchZones() {
    if (kicksharingAPITokenStoreInstance.token && kicksharingAPITokenStoreInstance.confirmedToken) {
      try {
        const response = await fetch(`${apiUrl}/zones`, {
          method: 'GET',
                  headers: {
                      Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
                    },
        });
        const data = await response.json();
        runInAction(() => { this.zones = data; });
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    }
  }
}

const zonesStore = new ZonesStore();

export default zonesStore;