import { Alert, Box, Card, CardActions, CardContent, CircularProgress, Typography } from '@mui/material';
import { accessRightsStoreInstance } from 'AccessRightsStore';
import { kicksharingAPITokenStoreInstance } from 'KicksharingAPITokenStore';
import { apiUrl } from 'config';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { Buffer } from 'buffer';

export enum IncidentEventEnum {
  AssignSelf,
  UnassignSelf,
  Resolve
}

export type PushIncidentEventProps = {
  eventType: IncidentEventEnum
}

type OngoingState = {
  state: "Ongoing";
}

type SuccessfulState = {
  state :"Success";
}

type ErrorState = {
  state: "Failed",
  message: string
}

type EventRegistrationState = OngoingState | SuccessfulState | ErrorState;



type IncidentEventPersonAssignmentPayload = {
  type: "PersonAssigned",
  assigneeID: string,
  plainText: string
}

type IncidentEventPersonAssignmentCanceledPayload = {
  type: "PersonAssignmentCanceled",
  plainText: string
}

type IncidentEventResolvePayload = {
  type: "Resolved",
  plainText: string
}

type IncidentEventPayload = IncidentEventPersonAssignmentPayload | IncidentEventPersonAssignmentCanceledPayload | IncidentEventResolvePayload;

function PushIncidentEventCore(props: PushIncidentEventProps) {
    const { incidentId, encodedMessage } = useParams();
    const {eventType} = props;

    const [state,setState] = useState<EventRegistrationState>({state:"Ongoing"});

    React.useEffect(() => 
      autorun(() => {
        setState({
          state:"Ongoing"
        })
        
        const apiToken = kicksharingAPITokenStoreInstance.token;
        const accessRights = accessRightsStoreInstance.AccessRights;

        console.log("push incident event effect accessRights",accessRightsStoreInstance.AccessRights)

        if(!accessRights || !apiToken)
          return;        
        console.log("userId",accessRights.userID,"name",accessRights.userName)

        var payload:IncidentEventPayload = null;      

        switch(eventType) {
          case IncidentEventEnum.AssignSelf:
            payload = {
              type: "PersonAssigned",
              assigneeID: accessRights.userID,
              plainText: `Сотрудник ${accessRights.userName} принял инцидент для разрешения.`
            }
            break;
          case IncidentEventEnum.UnassignSelf:
            payload = {
              type: "PersonAssignmentCanceled",
              plainText: `Сотрудник ${accessRights.userName} отказался от участия в разрешении инцидента.`
            }
            break;
          case IncidentEventEnum.Resolve:
            const decodedMessageBuffer = Buffer.from(encodedMessage,'base64')
            const decodedMessage = decodedMessageBuffer.toString('utf8')
            console.log("decoded message",decodedMessage)
            payload = {
              type: "Resolved",
              plainText: `${accessRights.userName}: \"${decodedMessage}\"`
            }
            break;
          default:
            console.error("Unexpected incident event type", eventType);
        }

        const endpointAddr = `${apiUrl}/incidents/${incidentId}/events`
        console.log("Posting to",endpointAddr)

        fetch(endpointAddr, {
          method: 'POST',
          headers: {
            Authorization: `Token ${apiToken}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(payload)
        }).then(async res => {
          if(res.ok) {
            setState({
              state: "Success"
            })
            // window.close(); // browser rejects to close the window which was not opened by javascript
          } else {
            try{
              const statusMessage = await res.text();
              setState({
                state:"Failed",
                message: `${res.status}: ${res.statusText} (${statusMessage})`
              })
            }
            catch(e){
              setState({
                state:"Failed",
                message: `${res.status}: ${res.statusText} (${e})`
              })
            }
          }
        }).catch(e => {
          setState({
            state:"Failed",
            message: `${e}`
          })
        })
      }
    ),[eventType, incidentId, apiUrl,encodedMessage])
  
    var actionText:string = "";
    switch(props.eventType) 
    {
      case IncidentEventEnum.AssignSelf: actionText = "Назначение на инцидент..."; break;
      case IncidentEventEnum.UnassignSelf: actionText = "Отмена назначения на инцидент..."; break;
      case IncidentEventEnum.Resolve: actionText = "Отмечаем инцидент, как разрешенный..."; break;
    }

    var resultComponent : React.ReactElement = null;
    switch(state.state) {
      case "Failed":
        resultComponent = <Alert severity="error">Ошибка: {state.message}</Alert>;
        break;
      case "Ongoing":
        resultComponent = <CircularProgress sx={{p:4}} />;
        break;
      case "Success":
        resultComponent = <Alert severity="success">выполнено успешно (можете закрыть эту вкладку)</Alert>        

    }    
    const card = (
      <>
      <CardContent>
        <Typography variant="h5" component="div">
          {actionText}
        </Typography>
        {resultComponent}
    </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
      </>
      )

    return (
      <Box sx={{ minWidth: 275 }}>
        <Card variant="outlined">{card}</Card>
      </Box>
  
    );
  }

export const PushIncidentEvent = observer((props:PushIncidentEventProps) => <PushIncidentEventCore {...props} />)
