import React from "react";

import { observer } from "mobx-react-lite";

import * as UI from "@mui/material";
import * as Icons from "@mui/icons-material";
import NavigationMenu from "components/NavigationMenu/NavigationMenu";

import {
  Chart as ChartJS,
  Colors,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement,
} from "chart.js";

import { Line, Bar } from "react-chartjs-2";
import dayjs, { Dayjs } from "dayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {kicksharingAPITokenStoreInstance} from "KicksharingAPITokenStore";
import { apiUrl } from "config";
import { CityStats, FinanceTable } from "components/FinanceTable/FinanceTable";
import { formatCurrencyRUB } from "utils/formatters";

ChartJS.register(
  Colors,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  BarElement,
  Legend
);

enum StepType {
  hour = "hour",
  day = "day",
}

enum ChartView {
  bar = "bar",
  line = "line",
}

type Dataset = {
  newUsersChart: {
    [key: string]: Array<string>;
  };
  newRentsChart: {
    [key: string]: Array<{
      time: string;
      value: number;
    }>;
  };
  scooterCount:{[key: string]:number};
  newUsers: Array<number>;
  newRents: Array<number>;
  revenue: Array<number>;
};

export const FinanceStatsPage = observer(() => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      // colors: {
      //   forceOverride: true,
      // },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        beginAtZero: true,
        stacked: true,
      },
    },
  };

  type StatusType = "empty" | "loading" | "ready" | "error";

  const defaultFromTime = dayjs().subtract(1, "day").startOf("day");
  const defaultToTime =dayjs().startOf("day");

  // const defaultFromTime = dayjs().subtract(1, "week").startOf("day");
  // const defaultToTime = dayjs();

  const [status, setStatus] = React.useState<StatusType>("empty");
  const [error, setError] = React.useState<string>("");

  const [viewStep, setViewStep] = React.useState<StepType>(StepType.hour);
  const [viewChart, setViewChart] = React.useState<ChartView>(ChartView.bar);

  const [fromTime, setFromTime] = React.useState<Dayjs>(defaultFromTime);
  const [toTime, setToTime] = React.useState<Dayjs>(defaultToTime);

  const [dataset, setDataset] = React.useState<Dataset>({
    newUsersChart: {},
    newRentsChart: {},
    scooterCount:{},
    newUsers: [],
    newRents: [],
    revenue: [],
  });

  type LineDataset = {
    labels: Array<string>;
    datasets: any;
    options?: any;
  };
  const [users, setUsers] = React.useState<LineDataset>({
    labels: [],
    datasets: [],
  });
  const [ridesCount, setRidesCount] = React.useState<LineDataset>({
    labels: [],
    datasets: [],
  });
  const [ridesRevenues, setRidesRevenue] = React.useState<LineDataset>({
    labels: [],
    datasets: [],
  });

  const [summaryRevenue, setSummaryRevenue] = React.useState<number>(0);
  const [summaryRides, setSummaryRides] = React.useState<number>(0);
  const [summaryUsers, setSummaryUsers] = React.useState<number>(0);

  const [cities, setCities] = React.useState<{ [key: string]: boolean }>({});

  function calcDateFormat() {
    if (viewStep === StepType.hour) {
      if (fromTime.isSame(toTime, "day")) {
        return "HH:mm";
      } else {
        return "DD.MM HH:mm";
      }
    }
    return "DD.MM";
  }  

  async function action() {
    const host = apiUrl;
    // const host = "http://localhost:5165";

    const query = new URLSearchParams({
      fromTime: fromTime.toDate().toISOString(),
      toTime: toTime.toDate().toISOString(),
      extend: true.toString(),
    }).toString();
    const request = await fetch(`${host}/finance/byTime?${query}`, {
      headers: {
        Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
      },
    });

    const json = await request.json();

    setDataset(json);
  }

  async function handlerRequest() {
    if (status === "loading") {
      return;
    }
    try {
      setError("");
      setStatus("loading");
      await action();
      setStatus("ready");
    } catch (e) {
      setError(e.message);
      setStatus("error");
    }
  }

  const pallette = [
    [76, 160, 234],

    [247, 98, 131],
    [93, 192, 192],
    [248, 160, 65],
    [153, 97, 254],
    [250, 206, 89],
    [201, 203, 207],
  ] as Array<[number, number, number]>;

  function getColorByIndex(index: number, alpha = 1) {
    return `rgba(${pallette[index % pallette.length].join(",")}, ${alpha})`;
  }

  function getColorByCity(city: string, alpha = 1) {
    const index = Object.keys(cities).sort().indexOf(city);
    return getColorByIndex(index, alpha);
  }

  function getTimeZoneOffset() {
    const offset = new Date().getTimezoneOffset();
    const sign = offset < 0 ? "+" : "-";
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    if (minutes) {
      return `${sign}${hours}:${minutes}`;
    } else {
      return `${sign}${hours}`;
    }
  }

  function kopecks2Roubles(value: number): number {
    return Number.parseFloat((value / 100).toFixed(2));
  }

  React.useEffect(() => {
    const rentsChartData = dataset.newRentsChart;
    const usersChartData = dataset.newUsersChart;

    for (const city of Object.keys(rentsChartData)) {
      if (cities[city] === undefined) cities[city] = true;
    }

    for (const city of Object.keys(usersChartData)) {
      if (cities[city] === undefined) cities[city] = true;
    }

    setCities({ ...cities });
  }, [dataset]);

  React.useEffect(() => {
    const rentsChartData = dataset.newRentsChart;
    const usersChartData = dataset.newUsersChart;
    const scooterCountData = dataset.scooterCount;

    const citiesNames = Object.keys(cities).sort();

    const rentsCities = Object.keys(rentsChartData);
    const usersCities = Object.keys(usersChartData);

    const labels = new Array<string>();

    type ChartDataset = { [key: string]: Array<number> };

    const citiesUsers: ChartDataset = {};
    const citiesRides: ChartDataset = {};
    const citiesRevenues: ChartDataset = {};

    for (
      let date = fromTime;
      date.isBefore(toTime);
      date = date.add(1, viewStep)
    ) {
      labels.push(date.format(calcDateFormat()));
      for (const city of citiesNames) {
        let cityUsers = 0;
        let cityRides = 0;
        let cityRevenue = 0;

        if (cities[city] !== true) continue;

        // Processed rents chart
        if (rentsCities.includes(city)) {
          for (const step of rentsChartData[city]) {
            if (date.isSame(step.time, viewStep)) {
              cityRides += 1;
              cityRevenue += step.value;
            }
          }

          if (citiesRides[city] === undefined) {
            citiesRides[city] = new Array();
          }
          citiesRides[city].push(cityRides);

          if (citiesRevenues[city] === undefined) {
            citiesRevenues[city] = new Array();
          }
          const inRubles = kopecks2Roubles(cityRevenue);
          citiesRevenues[city].push(inRubles);
        }

        // Processed users chart
        if (usersCities.includes(city)) {
          for (const time of usersChartData[city]) {
            if (date.isSame(time, viewStep)) {
              cityUsers += 1;
            }
          }
          if (citiesUsers[city] === undefined) {
            citiesUsers[city] = new Array();
          }
          citiesUsers[city].push(cityUsers);
        }
      }
    }

    let summaryUsers = 0;

    let summaryRides = 0;

    let summaryRevenue = 0;

    for (const city of Object.keys(cities)) {
      if (cities[city] !== true) continue;
      // Add city stats to summary
      summaryUsers += dataset.newUsers[city] ?? 0;

      summaryRides += dataset.newRents[city] ?? 0;

      summaryRevenue += dataset.revenue[city] ?? 0;
    }
    // Convert kopecks to roubles in summary revenue
    summaryRevenue = kopecks2Roubles(summaryRevenue);

    setSummaryUsers(summaryUsers);
    setSummaryRides(summaryRides);
    setSummaryRevenue(summaryRevenue);
    setRidesCount({
      labels,
      datasets: Object.keys(citiesRides).map((city) => ({
        fill: true,
        label: city,
        borderColor: getColorByCity(city),
        backgroundColor: getColorByCity(city, 0.8),
        data: citiesRides[city],
      })),
    });
    setRidesRevenue({
      labels,
      datasets: Object.keys(citiesRevenues).map((city) => ({
        fill: true,
        label: city,
        borderColor: getColorByCity(city),
        backgroundColor: getColorByCity(city, 0.8),
        data: citiesRevenues[city],
      })),
    });
    setUsers({
      labels,
      datasets: Object.keys(citiesUsers).map((city) => ({
        fill: true,
        label: city,
        borderColor: getColorByCity(city),
        backgroundColor: getColorByCity(city, 0.8),
        data: citiesUsers[city],
      })),
    });
  }, [dataset, viewStep, cities]);

  React.useEffect(() => {
    setStatus("empty");
  
    if(toTime.diff(fromTime, "days") > 7) {
      // auto switching to days in case long interval is selected
      setViewStep(StepType.day)
    }    
  }, [fromTime, toTime]);

  React.useEffect(() => {
    if (status !== "empty") {
      return;
    }
    if (fromTime.isAfter(toTime)) {
      setStatus("ready");
      setError("Начальная дата должна быть раньше конечной");
      // } else if (diff > 7) {
      //   setRangeError("Диапазон не должен превышать 7 дней");
    } else {
      handlerRequest();
    }
  }, [status]);  
  

  const financeTableData:CityStats[] = Object.keys(cities).map((city) => {
    const cityRevenue = dataset.revenue[city] ?? 0
    const scooterCount = dataset.scooterCount[city] ?? 0;
    var normalizedRevenue:number;
    if(scooterCount == 0)
      normalizedRevenue = NaN;
    else
      normalizedRevenue = cityRevenue / scooterCount; // WARNING: beware of float division for money
    return {
      Name:city,
      Revenue: kopecks2Roubles(cityRevenue),
      ScooterCount: scooterCount,
      RevenuePerScooter: kopecks2Roubles(normalizedRevenue)
    }
  });

  return (
    <>
      <NavigationMenu></NavigationMenu>

      <UI.Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={status == "loading"}
      >
        <UI.Paper sx={{ px: 5, py: 2 }}>
          <UI.Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <UI.Typography variant="h5" component="div">
              Составляем отчёт
            </UI.Typography>
            <UI.CircularProgress color="inherit" sx={{ m: 2 }} />
          </UI.Box>
        </UI.Paper>
      </UI.Backdrop>

      <UI.Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!!error}
      >
        <UI.Paper sx={{ px: 5, py: 2 }}>
          <UI.Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <UI.Alert severity="error">
              <UI.AlertTitle>
                {"При построении последнего отчёта возникла ошибка"}
              </UI.AlertTitle>
              {error}
            </UI.Alert>

            <UI.Button
              fullWidth={true}
              variant="contained"
              color="error"
              onClick={() => setError("")}
              sx={{ mt: 2 }}
            >
              OK
            </UI.Button>
          </UI.Box>
        </UI.Paper>
      </UI.Backdrop>

      <UI.Container sx={{ mt: 2 }}>        
        <UI.Grid item container spacing={2} xs={12}>          
            <UI.Grid item container spacing={2} xs={12} md={9}>
              <UI.Grid item xs={12}>
                <UI.Paper sx={{ p: 2 }}>
                  <UI.Box>
                    <FinanceTable data={financeTableData} />
                  </UI.Box>
                </UI.Paper>
              </UI.Grid>
              <UI.Grid item container spacing={2} xs={12}>
                <UI.Grid item xs={12} md={6}>
                  <UI.Paper sx={{ p: 2 }}>
                    <UI.Typography variant="h6" component="div">
                      Новых пользователей: {summaryUsers}
                    </UI.Typography>
                    {viewChart == ChartView.bar ? (
                      <Bar options={options} data={users} />
                    ) : (
                      <Line options={options} data={users} />
                    )}
                  </UI.Paper>
                </UI.Grid>

                <UI.Grid item xs={12} md={6}>
                  <UI.Paper sx={{ p: 2 }}>
                    <UI.Typography variant="h6" component="div">
                      Всего {summaryRides} поездок
                    </UI.Typography>
                    {viewChart == ChartView.bar ? (
                      <Bar options={options} data={ridesCount} />
                    ) : (
                      <Line options={options} data={ridesCount} />
                    )}
                  </UI.Paper>
                </UI.Grid>

                <UI.Grid item xs={12} md={12}>
                  <UI.Paper sx={{ p: 2 }}>
                    <UI.Typography variant="h6" component="div">
                      Выручка ({formatCurrencyRUB(summaryRevenue)})
                    </UI.Typography>
                    <UI.Box sx={{ height: 250 }}>
                      {viewChart == ChartView.bar ? (
                        <Bar
                          options={{ maintainAspectRatio: false, ...options }}
                          data={ridesRevenues}
                        />
                      ) : (
                        <Line
                          options={{ maintainAspectRatio: false, ...options }}
                          data={ridesRevenues}
                        />
                      )}
                    </UI.Box>
                  </UI.Paper>
                </UI.Grid>              
              </UI.Grid>
            </UI.Grid>        
          <UI.Grid item xs={12} md={3}>
            <UI.Grid container spacing={2}>
              <UI.Grid item xs={12} md={12}>
                <UI.Paper sx={{ p: 2 }}>
                  <UI.Typography variant="h6" component="div">
                    Отображение
                  </UI.Typography>

                  <UI.Stack spacing={2} sx={{ mt: 2 }}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="ru"
                    >
                      <DateTimePicker
                        label="От"
                        value={fromTime}
                        closeOnSelect={true}
                        onAccept={(date) => date && setFromTime(date)}
                      />
                      <DateTimePicker
                        label="До"
                        value={toTime}
                        closeOnSelect={true}
                        onAccept={(date) => date && setToTime(date)}
                      />
                    </LocalizationProvider>
                    {status === "error" && (
                      <UI.Button
                        variant="contained"
                        color="warning"
                        startIcon={<Icons.Refresh />}
                        fullWidth={true}
                        onClick={() => setStatus("empty")}
                      >
                        Повторить
                      </UI.Button>
                    )}
                  </UI.Stack>
                </UI.Paper>
              </UI.Grid>

              <UI.Grid item xs={12} md={12}>
                <UI.Paper sx={{ p: 2 }}>
                  <UI.Typography variant="h6" component="div" sx={{ mb: 1 }}>
                    Шаг
                  </UI.Typography>
                  <UI.ToggleButtonGroup
                    fullWidth
                    value={viewStep}
                    exclusive
                    onChange={(_, value: StepType) =>
                      value && setViewStep(value)
                    }
                  >
                    <UI.ToggleButton value={StepType.day}>
                      {"День"}
                    </UI.ToggleButton>
                    <UI.ToggleButton value={StepType.hour}>
                      {"Час"}
                    </UI.ToggleButton>
                  </UI.ToggleButtonGroup>
                </UI.Paper>
              </UI.Grid>

              <UI.Grid item xs={12} md={12}>
                <UI.Paper sx={{ p: 2 }}>
                  <UI.Typography variant="h6" component="div" sx={{ mb: 1 }}>
                    Вид графика
                  </UI.Typography>
                  <UI.ToggleButtonGroup
                    fullWidth
                    value={viewChart}
                    exclusive
                    onChange={(_, value: ChartView) =>
                      value && setViewChart(value)
                    }
                  >
                    <UI.ToggleButton value={ChartView.line}>
                      {"Линия"}
                    </UI.ToggleButton>
                    <UI.ToggleButton value={ChartView.bar}>
                      {"Столбики"}
                    </UI.ToggleButton>
                  </UI.ToggleButtonGroup>
                </UI.Paper>
              </UI.Grid>

              <UI.Grid item xs={12} md={12}>
                <UI.Paper sx={{ p: 2 }}>
                  <UI.Typography variant="h6" component="div">
                    Города
                  </UI.Typography>

                  <UI.List>
                    {Object.keys(cities).map((key, index) => (
                      <UI.ListItemButton
                        onClick={() => {
                          cities[key] = !cities[key];
                          setCities({ ...cities });
                        }}
                      >
                        <UI.ListItemIcon>
                          <UI.Checkbox checked={cities[key]} />
                        </UI.ListItemIcon>
                        <UI.ListItemText primary={key} />
                        <UI.Box
                          sx={{
                            width: 50,
                            height: 10,
                            borderRadius: 3,
                            bgcolor: getColorByCity(key),
                          }}
                        ></UI.Box>
                      </UI.ListItemButton>
                    ))}
                  </UI.List>
                </UI.Paper>
              </UI.Grid>
            </UI.Grid>
          </UI.Grid>
        </UI.Grid>
      </UI.Container>
    </>
  );
});
