
export type RussianCases ={
    nominative:string
    genitive:string
}

export function useVehicleTypeNames(vehicle: string):RussianCases {
    switch(vehicle){
        case "Car":
            return {
                nominative:"автомобиль",
                genitive:"автомобиля"
            }
        case "Scooter": 
        return{
            nominative:"самокат",
            genitive:"самоката"
        };
        default:
            return{
                nominative:"",
                genitive:""
            };
    }
}