import React from "react";
import "./App.css";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import Auth from "./pages/auth/auth";
import PhoneCodeInput from "./pages/verification/verification";
import MapPage from "pages/map/map";
import ScooterList from "components/ScooterList/ScooterList";
import ScooterPage from "components/ScooterPage/ScooterPage";
import {kicksharingAPITokenStoreInstance} from "KicksharingAPITokenStore";
import { Observer, observer } from "mobx-react-lite";
import { LastRentsTrack } from "pages/track/LastRentsTrack";
import { FinanceStatsPage } from "pages/finance/FinanceStatsPage";
import { ScooterHistory } from "pages/history/ScooterHistory";
import { IncidentEventEnum, PushIncidentEvent } from "pages/pushIncidentEvent/PushIncidentEvent";

const App: React.FC = observer(() => {
  const isLoggedIn = kicksharingAPITokenStoreInstance.confirmedToken && kicksharingAPITokenStoreInstance.token;

  return (
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={isLoggedIn ? <Navigate to="/scooters" /> : <Auth />}
        />
        <Route
          path="/verification"
          element={<PhoneCodeInput onCodeChange={(code) => console.log("")} />}
        />
        {isLoggedIn && (
          <>
            <Route path="/map" element={<MapPage />} />
            <Route path="/scooters" element={<ScooterList />} />
            <Route path="/scooters/:scooterId" element={<ScooterPage />} />
            <Route path="/scooters/:scooterId/history/:latlon?" element={<ScooterHistory />} />
            <Route path="/scootersTrack" element={<LastRentsTrack />} />
            <Route path="/financeStats" element={<FinanceStatsPage />} />
            <Route path="/incidents/:incidentId/assign-self" element={<PushIncidentEvent eventType={IncidentEventEnum.AssignSelf} />} />
            <Route path="/incidents/:incidentId/unassign-self" element={<PushIncidentEvent eventType={IncidentEventEnum.UnassignSelf} />} />
            <Route path="/incidents/:incidentId/resolve/:encodedMessage" element={<PushIncidentEvent eventType={IncidentEventEnum.Resolve} />} />

            
          </>
        )}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </HashRouter>
  );
});

export default App;
