import React from 'react';
import Map from 'components/Map/Map';
import { LatLng } from 'leaflet';
import './map.css';
import NavigationMenu from 'components/NavigationMenu/NavigationMenu';
import { useFreshScooterData } from 'hooks/useFreshScooterData';

const MapPage = () => {
  const scrollWheelZoom = false;

  return (
    <div>
      <NavigationMenu></NavigationMenu>
      <div className='Map__container'>
        <Map scrollWheelZoom={scrollWheelZoom}>
        </Map>
      </div>
    </div>
  );
};

export default MapPage;