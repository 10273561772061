import React from "react";
import { observer } from "mobx-react-lite";

import * as Map from "react-leaflet";
import * as LeafLet from "leaflet";
import * as UI from "@mui/material";
import * as Icons from "@mui/icons-material";

import "./style.css";

import NavigationMenu from "components/NavigationMenu/NavigationMenu";
import {kicksharingAPITokenStoreInstance} from "KicksharingAPITokenStore";
import {
  DateRange,
  DateRangePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ru";
import { apiUrl } from "config";
import { usePersistentMapViewport } from "hooks/usePersistentMapViewport";

dayjs.locale("ru");

export const LastRentsTrack = observer(() => {
  const [map, setMap] = React.useState<LeafLet.Map>(null);

  const [showToolbox, setShowToolbox] = React.useState<boolean>(true);

  
  const [opacity, setOpacity] = React.useState<number>(0.1);

  const [startThreshold, setStartThreshold] = React.useState<number>(60);
  const [finishThreshold, setFinishThreshold] = React.useState<number>(60);

  const [showFull, setShowFull] = React.useState<boolean>(true);
  const [showStart, setShowStart] = React.useState<boolean>(true);
  const [showFinish, setShowFinish] = React.useState<boolean>(true);

  const lineWeight = 10;

  const fullLineView = {
    color: "red",
    opacity: opacity,
    weight: showFull ? lineWeight : 0,
  };
  const startLineView = {
    color: "green",
    opacity: opacity,
    weight: showStart ? lineWeight : 0,
  };
  const finishLineView = {
    color: "blue",
    opacity: opacity,
    weight: showFinish ? lineWeight : 0,
  };

  const [lines, setLines] = React.useState<{
    fullList?: Array<Array<[number, number]>>;
    startList?: Array<Array<[number, number]>>;
    finishList?: Array<Array<[number, number]>>;
  }>({
    fullList: [],
    startList: [],
    finishList: [],
  });

  type StatusType = "empty" | "loading" | "ready";
  const [status, setStatus] = React.useState<StatusType>("empty");
  const [error, setError] = React.useState<string>("");

  async function handlerUpdate() {
    try {
      setStatus("loading");
      setError("");
      await action();
      setStatus("ready");
    } catch (e) {
      setError(e.message);
      setStatus("empty");
    }
  }

  function getTimeZoneOffset() {
    const offset = new Date().getTimezoneOffset();
    const sign = offset < 0 ? "+" : "-";
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    if (minutes) {
      return `${sign}${hours}:${minutes}`;
    } else {
      return `${sign}${hours}`;
    }
  }

  const minuteForms = ["минута", "минуты", "минут"];
  const secondForms = ["секунда", "секунды", "секунд"];

  function getForm(value: number, forms: string[]) {
    if (value % 10 === 1 && value % 100 !== 11) {
      return forms[0];
    } else if (
      value % 10 >= 2 &&
      value % 10 <= 4 &&
      (value % 100 < 10 || value % 100 >= 20)
    ) {
      return forms[1];
    } else {
      return forms[2];
    }
  }

  function secondsFormatter(seconds: number) {
    if (seconds > 60) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      const minuteForm = getForm(minutes, minuteForms);
      const secondForm = getForm(remainingSeconds, secondForms);

      return `${minutes} ${minuteForm} ${remainingSeconds} ${secondForm}`;
    } else {
      const secondForm = getForm(seconds, secondForms);
      return `${seconds} ${secondForm}`;
    }
  }

  const [fromTime, setFromTime] = React.useState<Dayjs>(
    dayjs().subtract(1, "day").startOf("day")
  );
  const [toTime, setToTime] = React.useState<Dayjs>(dayjs().startOf("day"));

  async function action() {
    
    const query = new URLSearchParams({
      fromTime: fromTime.toDate().toISOString(),
      toTime: toTime.toDate().toISOString(),
      expand: true.toString(),
      onlyFinished: true.toString(),
    }).toString();

    const byTimeReq = await fetch(`${apiUrl}/rents?${query}`, {
      headers: {
        Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
      },
    });

    const byTime = (await byTimeReq.json()) as Array<{
      totalDistance: number;
      startTime: string;
      rideEndTime: string;
      scooterID: string;
    }>;

    const trackReqPromises = new Array<Promise<Response>>();
    for (const item of byTime) {
      if (!item.totalDistance) {
        continue;
      }

      const scooterId = item.scooterID;
      const startTime = item.startTime;
      const endTime = item.rideEndTime;
      if (!scooterId || !startTime || !endTime) {
        continue;
      }

      const query = new URLSearchParams({
        startTime: startTime,
        endTime: endTime,
      }).toString();

      const req = fetch(`${apiUrl}/scooters/${scooterId}/track?${query}`, {
        headers: {
          Authorization: `Token ${kicksharingAPITokenStoreInstance.token}`,
        },
      });

      trackReqPromises.push(req);
    }

    const trackReq = await Promise.all(trackReqPromises);

    const tracks = (await Promise.all(
      trackReq.map((req) => req.json())
    )) as Array<
      Array<{
        time: string;
        lat: number;
        lon: number;
      }>
    >;

    const fullList = new Array<Array<[number, number]>>();
    const startList = new Array<Array<[number, number]>>();
    const finishList = new Array<Array<[number, number]>>();

    let currentStartTime: Date;
    let currentFinishTime: Date;

    let currentFull: Array<[number, number]>;
    let currentStart: Array<[number, number]>;
    let currentFinish: Array<[number, number]>;
    for (const track of tracks) {
      const pointCount = track.length;

      for (let i = 0; i < pointCount; i++) {
        const point = track[i];
        const timestamp = new Date(point.time);
        if (i === 0) {
          currentStartTime = timestamp;

          currentFull = new Array<[number, number]>();
          fullList.push(currentFull);

          currentStart = new Array<[number, number]>();
          startList.push(currentStart);
        }
        const timeFromStart = timestamp.getTime() - currentStartTime.getTime();

        if (timeFromStart <= 1000 * startThreshold) {
          currentStart.push([point.lon, point.lat]);
        }
        currentFull.push([point.lon, point.lat]);
      }
      for (let i = pointCount - 1; i >= 0; i--) {
        const point = track[i];
        const timestamp = new Date(point.time);

        if (i == pointCount - 1) {
          currentFinishTime = timestamp;
          currentFinish = new Array<[number, number]>();
          finishList.push(currentFinish);
        }
        const timeFromFinish =
          currentFinishTime.getTime() - timestamp.getTime();

        if (timeFromFinish > 1000 * finishThreshold) {
          break;
        }
        currentFinish.push([point.lon, point.lat]);
      }
    }
    setLines({
      fullList,
      startList,
      finishList,
    });
  }
  const [rageError, setRangeError] = React.useState("");

  React.useEffect(() => {
    const diff = toTime.diff(fromTime, "day");
    if (diff < 0) {
      setRangeError("Начальная дата должна быть раньше конечной");
    } else if (diff > 7) {
      setRangeError("Диапазон не должен превышать 7 дней");
    } else {
      setRangeError("");
    }
  }, [fromTime, toTime]);

  const initialViewport = usePersistentMapViewport(map, "tracks")

  return (
    <>
      <UI.Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={status !== "ready"}
      >
        <UI.Paper sx={{ px: 5, py: 2 }}>
          <UI.Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {status == "loading" && (
              <>
                <UI.Typography variant="h5" component="div">
                  Составляем отчёт
                </UI.Typography>
                <UI.CircularProgress color="inherit" sx={{ m: 2 }} />
              </>
            )}
            {status == "empty" && (
              <UI.Stack>
                <UI.Typography
                  variant="h5"
                  sx={{ textAlign: "center" }}
                  component="div"
                >
                  Отчёт о перемещении скутеров
                </UI.Typography>

                <UI.Typography
                  sx={{ mt: 2 }}
                >{`Диапазон дат (часовой пояс ${getTimeZoneOffset()})`}</UI.Typography>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="ru"
                >
                  <UI.Stack direction="row" spacing={2}>
                    <DateTimePicker
                      disableFuture={true}
                      value={fromTime}
                      onChange={(newValue: Dayjs) => setFromTime(newValue)}
                    />
                    <DateTimePicker
                      disableFuture={true}
                      value={toTime}
                      onChange={(newValue: Dayjs) => setToTime(newValue)}
                    />
                  </UI.Stack>
                </LocalizationProvider>
                {rageError && (
                  <UI.Alert severity="error" sx={{ mt: 2 }}>
                    <UI.AlertTitle>{rageError}</UI.AlertTitle>
                    {error}
                  </UI.Alert>
                )}

                <UI.Typography sx={{ mt: 2 }}>
                  {`Время от начала поездки ${secondsFormatter(
                    startThreshold
                  )}`}
                </UI.Typography>
                <UI.Slider
                  value={startThreshold}
                  valueLabelDisplay="off"
                  onChange={(e, v) => setStartThreshold(Number(v))}
                  step={10}
                  min={10}
                  max={600}
                />

                <UI.Typography sx={{ mt: 2 }}>
                  {`Время до завершения ${secondsFormatter(finishThreshold)}`}
                </UI.Typography>
                <UI.Slider
                  value={finishThreshold}
                  valueLabelDisplay="off"
                  onChange={(e, v) => setFinishThreshold(Number(v))}
                  step={10}
                  min={10}
                  max={600}
                />
                {error && (
                  <UI.Alert severity="error">
                    <UI.AlertTitle>
                      {"При построении последнего отчёта возникла ошибка"}
                    </UI.AlertTitle>
                    {error}
                  </UI.Alert>
                )}

                <UI.Button
                  disabled={!!rageError}
                  fullWidth
                  sx={{ my: 2 }}
                  variant="contained"
                  color="primary"
                  // startIcon={<Icons.Update />}
                  onClick={handlerUpdate}
                >
                  Составить отчёт
                </UI.Button>
              </UI.Stack>
            )}
          </UI.Box>
        </UI.Paper>
      </UI.Backdrop>
      <UI.Box
        sx={{ zIndex: 99999, position: "absolute", top: 0, left: 0, right: 0 }}
      >
        <NavigationMenu></NavigationMenu>
      </UI.Box>

      <UI.Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
        }}
      >
        <Map.MapContainer
          center={initialViewport.centre}
          zoom={initialViewport.zoomLevel}
          scrollWheelZoom={true}
          zoomControl={false}
          ref={setMap}
        >
          <Map.TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

          {lines.fullList.map((polyline, index) => (
            <Map.Polyline pathOptions={fullLineView} positions={polyline} />
          ))}

          {lines.startList.map((polyline, index) => (
            <Map.Polyline pathOptions={startLineView} positions={polyline} />
          ))}

          {lines.finishList.map((polyline, index) => (
            <Map.Polyline pathOptions={finishLineView} positions={polyline} />
          ))}
        </Map.MapContainer>
      </UI.Box>

      <UI.Box
        sx={{
          zIndex: 1000,
          position: "absolute",
          top: "max(45px, 5vw)",
          right: "1vw",
        }}
      >
        <UI.Paper sx={{ p: 2 }}>
          <UI.Paper>
            {!showToolbox && (
              <UI.Button fullWidth onClick={() => setShowToolbox(true)}>
                Показать меню
              </UI.Button>
            )}
            {showToolbox && (
              <UI.Button fullWidth onClick={() => setShowToolbox(!true)}>
                Скрыть меню
              </UI.Button>
            )}
          </UI.Paper>
          <UI.Paper sx={{ p: 2, mt: 2, display: showToolbox ? "" : "none" }}>
            <UI.FormGroup>
              {/* <UI.Typography variant="h6">Отображение</UI.Typography> */}
              <UI.Typography gutterBottom>Отображение</UI.Typography>
              <UI.FormControlLabel
                control={
                  <UI.Checkbox
                    checked={showStart}
                    onChange={(event) => setShowStart(event.target.checked)}
                  />
                }
                label="Начало аренд"
              />
              <UI.FormControlLabel
                control={
                  <UI.Checkbox
                    checked={showFinish}
                    onChange={(event) => setShowFinish(event.target.checked)}
                  />
                }
                label="Завершение аренд"
              />
              <UI.FormControlLabel
                control={
                  <UI.Checkbox
                    checked={showFull}
                    onChange={(event) => setShowFull(event.target.checked)}
                  />
                }
                label="Полный трек"
              />
            </UI.FormGroup>
          </UI.Paper>
          <UI.Paper sx={{ p: 2, mt: 2, display: showToolbox ? "" : "none" }}>
            <UI.Typography gutterBottom>Непрозрачность</UI.Typography>

            <UI.Slider
              value={opacity}
              onChange={(event, value) => setOpacity(Number(value))}
              aria-label="Default"
              valueLabelDisplay="auto"
              max={1}
              step={0.1}
              min={0}
            />

            {/* <UI.Button
                variant="contained"
                color="primary"
                startIcon={<Icons.Update />}
                onClick={handlerUpdate}
              >
                Обновить
              </UI.Button> */}
          </UI.Paper>
          <UI.Paper sx={{ p: 2, mt: 2, display: showToolbox ? "" : "none" }}>
            <UI.Typography gutterBottom>Легенда карты</UI.Typography>

            <UI.FormGroup>
              <UI.FormControlLabel
                control={
                  <UI.Box
                    sx={{
                      mx: 2,
                      width: 30,
                      height: 10,
                      bgcolor: "green",
                      borderRadius: "5px",
                    }}
                  ></UI.Box>
                }
                label="Начало аренд"
              />
              <UI.FormControlLabel
                control={
                  <UI.Box
                    sx={{
                      mx: 2,
                      width: 30,
                      height: 10,
                      bgcolor: "blue",
                      borderRadius: "5px",
                    }}
                  ></UI.Box>
                }
                label="Завершение аренд"
              />
              <UI.FormControlLabel
                control={
                  <UI.Box
                    sx={{
                      mx: 2,
                      width: 30,
                      height: 10,
                      bgcolor: "red",
                      borderRadius: "5px",
                    }}
                  ></UI.Box>
                }
                label="Полный трек"
              />
            </UI.FormGroup>
          </UI.Paper>

          {status === "ready" && (
            <UI.Paper sx={{ p: 2, mt: 2, display: showToolbox ? "" : "none" }}>
              <UI.Typography gutterBottom>
                <strong>Начало отчёта:</strong>&nbsp;
                {fromTime.locale("ru").format("DD.MM.YYYY HH:mm")}
              </UI.Typography>

              <UI.Typography gutterBottom>
                <strong>Конец отчёта:</strong>&nbsp;
                {toTime.locale("ru").format("DD.MM.YYYY HH:mm")}
              </UI.Typography>
            </UI.Paper>
          )}

          <UI.Paper sx={{ p: 2, mt: 2, display: showToolbox ? "" : "none" }}>
            <UI.Button
              fullWidth
              variant="contained"
              color="info"
              onClick={() => setStatus("empty")}
            >
              Новый отчёт
            </UI.Button>
          </UI.Paper>
        </UI.Paper>
      </UI.Box>
    </>
  );
});
