import { scooterStoreInstance } from "ScootersStore";
import { runInAction } from "mobx";
import { useEffect } from "react";


/** Notifies the MobX scooter store that the fresh scooter data is required */
export function useFreshScooterData() {
    useEffect(() => {
        // console.log("Increasing fresh scooter consumer counter")
        runInAction(() => scooterStoreInstance.increaseConsumerCount())
        

        return () => runInAction(() => {
            // console.log("Decreasing fresh scooter consumer count")
            scooterStoreInstance.decreaseConsumerCount()
        })
    },[])
}