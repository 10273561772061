export interface Dictionary {
    [key: string]: string;
}

export const deactivationDict: Dictionary = {
    LowCharge: 'Требуется зарядка',
    Stolen: 'Угон/розыск',
    Transportation: 'Перемещение',
    InitialPreparation: 'Первичная подготовка к эксплуатации',
    Repair: 'Ремонт',
    DiagnosticsNeeded: 'Требуется диагностика',
    Storage: 'Хранение',
    UsedByStaff: 'В пользовании персоналом',
    SparePartsDonor: 'Донор запчастей',
};